import { ErrorOutline, WarningAmberOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Stack, Typography } from "@mui/material";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import React from "react";
import { PonziContract } from "../utils/ponziContract2";
import HighlightOffOutlinedIcon  from '@mui/icons-material/HighlightOffOutlined';

export default function WalletAlert({ponziContract, setPonziContract, showButton}) {
    const [walletDialogOpen, setWalletDialogOpen] = React.useState(false);

    if (ponziContract.hasSigner) return;
    
    return (
        
    <Box sx={{marginY:0, flexDirection:"row"}} maxWidth="sm" display="flex">
      
    {showLinkOrButton(showButton, setWalletDialogOpen)}
    {showWalletDialog(walletDialogOpen, setWalletDialogOpen, ponziContract, setPonziContract)}
    </Box>
    )

}

function showLinkOrButton(showButton, setWalletDialogOpen) {
    if (!showButton) {
        return (      
            <>
            <WarningAmberOutlined size="large" color="error" sx={{marginY:0}}/>&nbsp;  
            <Typography variant="p" component="p" color="error" sx={{marginY:0}}>        
            <Link href="#" onClick={()=>setWalletDialogOpen(true)} color="error">Wallet Not Connected</Link>
            </Typography>  
            </>           
        )
    }
    else {
        return (
            <>
            <WarningAmberOutlined size="large" color="error" sx={{marginY:1}}/>&nbsp;  
            <Typography variant="p" component="p" color="error" sx={{marginY:1}}>  
                Wallet Not Connected.&nbsp;
            </Typography>
            <Button variant="outlined" size="small" sx={{ borderRadius:4 }}
            onClick={()=>setWalletDialogOpen(true)}>Connect Wallet</Button>
            </>

        )
    }
}

function showSignerWarning(ponziContract) {
    if (ponziContract.hasSigner) return;
    return (
        <Box sx={{marginY:0, flexDirection:"row"}} maxWidth="sm" display="flex">
        <ErrorOutline size="large" color="error" sx={{marginY:0}}/>
        <Typography variant="p" component="p" color="error">        
            &nbsp;Wallet Not Connected
        </Typography>
        </Box>
    )
}

function showWalletDialog(walletDialogOpen, setWalletDialogOpen, ponziContract, setPonziContract) {
    if (walletDialogOpen) {
        return (
            <Dialog open={walletDialogOpen} 

            onClose={()=>setWalletDialogOpen(false)}
            aria-labelledby="wallet-dialog-title"
            aria-describedby="wallet-dialog-description">
            
            <DialogTitle id="wallet-dialog-title">
                Connect
            </DialogTitle>
            <DialogContent>                
                <DialogContentText id="wallet-dialog-description">
                Connect with your wallet of choice to trade {ponziContract.sym}
                <br/>
                <Stack sx={{marginY:2}}>
                <Button variant="outlined" target="_blank" href="dapp://ponzicoin.app/" 
                    sx={{ borderRadius:8 }}
                    style={{justifyContent: "flex-start"}}>
                    <img width="28" height="28" src="metamask.png" alt="Metamask Logo"/>&nbsp;Metamask
                </Button>
                <br/>
                <Button variant="outlined" target="_blank" href="https://link.trustwallet.com/open_url?coin_id=714&url=https://ponzicoin.app/" 
                    sx={{ borderRadius:8 }}
                    style={{justifyContent: "flex-start"}}>
                    <img width="28" height="28" src="trustwallet.svg" alt="Trust Wallet Logo"/>&nbsp;Trust Wallet
                </Button>                
                <br/>
                <Button variant="outlined" target="_blank" onClick={()=>{                    
                          const wcProvider = new WalletConnectProvider({
                            infuraId: PonziContract.infuraId
                            });
                          wcProvider.enable().then(()=>{
                            let prv = new ethers.providers.Web3Provider(wcProvider);    
                            setPonziContract(new PonziContract(prv));                    
                          });
                        }}
                    sx={{ borderRadius:8 }}
                    style={{justifyContent: "flex-start"}}>
                    <img width="28" height="28" src="walletconnect.png" alt="Wallet Connect logo"/>&nbsp;WalletConnect
                </Button>                
                <br/>                
                <Button variant="outlined" target="_blank" href="mathwallet://mathwallet.org?action=link&value=https://ponzicoin.app/"
                    sx={{ borderRadius:8 }}
                    style={{justifyContent: "flex-start"}}>
                    
                    <img width="28" height="28" src="mathwallet.png" alt="Math Wallet Logo"/>&nbsp;Math Wallet
                </Button>
                
                </Stack>
                </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={()=>setWalletDialogOpen(false)}><HighlightOffOutlinedIcon/></Button>
            </DialogActions>
        </Dialog>                
        )
    }
}
