
import { AnalyticsRounded, FastForwardRounded, Help, PlayArrowRounded, SpeedRounded, StopRounded, TrendingUpRounded, Tune, TuneRounded } from "@mui/icons-material";
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, OutlinedInput, Popover, Slider, Stack, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography } from "@mui/material";

import React from "react";
import { PonziContract } from "../utils/ponziContract2";
import { PonziSimulatorProvider } from "../utils/ponziSimulatorProvider";
import { cleanInteger, deserializeState, serializeState } from "../utils/utils";

import Trade from "./Trade";
import TradeStats from "./TradeStats";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ethers } from 'ethers';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import {useSemiPersistentState} from "../utils/utils";

const { utils, BigNumber } = require("ethers");
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  
const randomTrade = async (buySellProb, ponziContract, user, state, setSnackbarOpen, setSnackbarMessage, addTransaction, fiatCcy, ethRates) => {    
    let sideProb = buySellProb/100;
    let side = (Math.random()<sideProb)?'buy' : 'sell';    
    
    if (state.userTokens[user].eq(0)) {
        side='buy';
    }
    let sender, numTokens, price, val;    
    try {
        
        //ponziContract.contract.connect((user==='others') ? ponziContract.provider.getOthersSigner() : ponziContract.provider.getSigner());
        
        let txn={id:PonziContract.getRandomId(), sentTime:new Date(), sender:user, side:side};
        if (side==='buy') {
            let buyValue = Math.random()*Math.min(5.0, parseFloat(utils.formatEther(state.userBalance[user])));
            buyValue = utils.parseUnits(buyValue.toFixed(6)); 
            txn['sentVal']=buyValue;
            let tx = await ponziContract.contract.mint({value:buyValue});
            let res=await tx.wait();
            let ev = res.events.find(event => event.event==='PonziMint');
            [sender, numTokens, price, val] = ev.args;  

        }
        else if (side==='sell') {            
            let sellTokens = Math.random()*Math.min(100000, parseFloat(utils.formatEther(state.userTokens[user])/10));
            sellTokens = utils.parseUnits(sellTokens.toFixed(6));
            txn['sentTokens']=sellTokens;
            let tx = await ponziContract.contract.burn(sellTokens);
            let res=await tx.wait();
            let ev = res.events.find(event => event.event==='PonziBurn');
            [sender, numTokens, price, val] = ev.args;                 
        }
        txn['recdTime']=new Date(); 
        txn['recdTokens']=numTokens;
        txn['recdPrice']=price;
        txn['recdVal']=val;
        txn['fiatCcy']=fiatCcy;        
        txn['fiatVal']=((Object.keys(ethRates).length>0) ? (ethRates.rates[fiatCcy]*parseFloat(utils.formatEther(val))) : 0)
        addTransaction(txn);

        let msg = '';
        
        msg = msg + ((user==='user') ? 'You ' : 'Others ');
        msg = msg + ((side==='buy') ? 'bought ' : 'sold ');
        msg = msg + ponziContract.sym+" "+PonziContract.formatNumber(utils.formatEther(numTokens), 6);
        msg = msg + " @ "+ponziContract.ccy+" "+PonziContract.formatNumber(utils.formatEther(price), 8);
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
    }
    catch (e) {        
        ethers.logger.info("Error generating random trade: "+e.toString());
        console.log(e);
    }
}



  export default function TradeSimulator({fiatCcy, isIdle}) {  
      const initState = {
          
          contractBalance:BigNumber.from('0'), 
          contractTokens:BigNumber.from('0'),
          userBalance:{
              user:utils.parseUnits('1000'),
              others:utils.parseUnits('100000')
          },
          userTokens:{
              user:BigNumber.from('0'),
              others:BigNumber.from('0')
          },
          mintValue:{
            user:BigNumber.from('0'),
            others:BigNumber.from('0')              
          },
          burnValue:{
            user:BigNumber.from('0'),
            others:BigNumber.from('0')              
          },
          transactions:[]
        };
      const initTxnState = {txns:[]};
      const [ethConvRates, setEthConvRates] = React.useState({});      
      const [state, setState] = useSemiPersistentState('simState4', serializeState(initState));      
      const [ponziContractSim, setPonziContractSim] = React.useState(null);
      const [openTab, setOpenTab] = useSemiPersistentState('openTab1', 'trade'); //React.useState('trade');
      const [snackbarOpen, setSnackbarOpen] = React.useState(false);
      const [snackbarMessage, setSnackbarMessage] = React.useState('');
      const [activeUser, setActiveUser] = useSemiPersistentState('activeUser1', 'user'); // React.useState('user');
      const [playRandomTrades, setPlayRandomTrades] = React.useState(false);
      const [refresh, setRefresh] = React.useState(0);
      const [buySellProb, setBuySellProb] = useSemiPersistentState('buySellProb1', 75); //React.useState(50);
      const [tradeIntervalMs, setTradeIntervalMs] = useSemiPersistentState('tradeIntervalMs1', 1000); //React.useState(1000);
      const [transactions, setTransactions] = useSemiPersistentState('txnsSim', serializeState(initTxnState));
      const [helpOpen, setHelpOpen] = React.useState(false);
      
      const addTransaction= (txn) => {
        let t = deserializeState(transactions);
        t.txns.unshift(txn);    
        let serT = serializeState(t);
        //localStorage.setItem('txns2', serT);    
        setTransactions(serT);        
    }

      React.useEffect(()=>{        
        let provider = new PonziSimulatorProvider(deserializeState(state), setState);
        provider.setConnectedUser(activeUser);
        //let provider = new PonziSimulatorProvider(initState, setState);
        if (provider) {
            //let newContract = new PonziContract(provider, true);
            let pz = new PonziContract(provider, true);
            setPonziContractSim(pz);
            pz.getEthConvRates(setEthConvRates);
            const interval = setInterval(()=>{
                if (ponziContractSim) {
                    console.log("Fetching eth conv rates - int");
                 ponziContractSim.getEthConvRates(setEthConvRates);
                }}, 60000);
            return ()=>clearInterval(interval);
    
        }

      }, []);
      
      React.useEffect(() => {
        if (playRandomTrades) {            
            const interval = setInterval(()=>{
                randomTrade(buySellProb, ponziContractSim, activeUser, deserializeState(state), setSnackbarOpen, setSnackbarMessage, addTransaction, 
                    fiatCcy, ethConvRates);
                if (refresh>1000000) {
                    setRefresh(0);
                }
                else {
                    setRefresh(refresh+1);
                }
            }, tradeIntervalMs);
            return ()=>clearInterval(interval);            
        }
      }, [playRandomTrades,ponziContractSim, activeUser, state, fiatCcy, ethConvRates])


      const reset = () => {
          setPlayRandomTrades(false);
          setBuySellProb(75);
          setTradeIntervalMs(1000);
          let freshState = serializeState(initState);
          setState(freshState);
          setTransactions(serializeState(initTxnState));
          let provider = new PonziSimulatorProvider(initState, setState);
          if (provider) {
              setPonziContractSim(new PonziContract(provider, true))
          }  
      }

      const handleOpenTab = (event, newTab) => {
          if (!newTab || openTab===newTab) return;
          setOpenTab(newTab);
      }
      return (                              
        <Container maxWidth="sm">            
            <Toolbar edge="start" sx={{ mr: 2, justifyContent:"center", alignContent:"center", alignItems:"center", marginX:0}}>
            {/*showTradeStatsBar(openTab, fiatCcy, ponziContractSim, activeUser, setActiveUser.bind(this))*/}
            <ToggleButtonGroup value={openTab} exclusive onChange={handleOpenTab} aria-label="choose tab" sx={{marginLeft:2}}>
                <ToggleButton value="trade" variant="outlined" sx={{marginLeft:1, borderTopLeftRadius:15, borderTopRightRadius:15}} color="primary"><TrendingUpRounded/>&nbsp;Trade</ToggleButton>
                <ToggleButton value="tradeStats" variant="outlined" sx={{marginLeft:1, borderTopLeftRadius:15, borderTopRightRadius:15}} color="primary"><AnalyticsRounded/>&nbsp;Stats</ToggleButton>
            </ToggleButtonGroup>
            <Button variant="outlined" onClick={reset} sx={{marginLeft:1, borderRadius:8}}>Reset</Button>
            
            <Help onClick={()=>{setHelpOpen(true)}} sx={{marginLeft:2}} color="primary"/>
            </Toolbar>
            <Toolbar edge="start" sx={{ mr: 2, justifyContent:"center", alignContent:"center", alignItems:"center", marginX:0}}>
                <TradeBar openTab={openTab} fiatCcy={fiatCcy} ponziContract={ponziContractSim} setPonziContract={setPonziContractSim}
                    isIdle={isIdle} setSnackBarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} state={deserializeState(state)}
                    activeUser={activeUser} setActiveUser={setActiveUser} playRandomTrades={playRandomTrades} setPlayRandomTrades={setPlayRandomTrades}
                    refresh={refresh} setRefresh={setRefresh} buySellProb={parseFloat(buySellProb)} setBuySellProb={setBuySellProb}
                    tradeIntervalMs={parseFloat(tradeIntervalMs)} setTradeIntervalMs={setTradeIntervalMs} addTransaction={addTransaction}
                    ethConvRates={ethConvRates}/>

            </Toolbar>
            <HelpDialog ponziContract={ponziContractSim} helpOpen={helpOpen} setHelpOpen={setHelpOpen}/>
            {/*<Dialog open={true}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  opacity:2,
                  boxShadow: 'none',
                },
              }}>
                <DialogContent>This is some test content</DialogContent>
            </Dialog>*/}
            <Snackbar open={snackbarOpen} onClose={()=>setSnackbarOpen(false)} autoHideDuration={7000}>
                <Alert onClose={()=>setSnackbarOpen(false)} severity="success" sx={{width:'100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <OpenTab openTab={openTab} fiatCcy={fiatCcy} ponziContract={ponziContractSim} isIdle={isIdle} activeUser={activeUser} refresh={refresh}
                transactions={transactions} setTransactions={setTransactions} />            
        </Container>
      )

  }
  
  

  function TradeBar({openTab, fiatCcy, ponziContract, setPonziContract, isIdle, setSnackBarOpen, setSnackbarMessage, state, activeUser, setActiveUser, 
    playRandomTrades, setPlayRandomTrades, refresh, setRefresh, buySellProb, setBuySellProb, tradeIntervalMs, setTradeIntervalMs, addTransaction, ethConvRates}) {
      const [tunerAnchor, setTunerAnchor] = React.useState(null);
      
      //const [buySellProb, setBuySellProb] = React.useState(50);
      if (!ponziContract) return;
      if (openTab!=='trade' && openTab!=='tradeStats') return;

      const playTrade = async () => {
        await randomTrade(buySellProb, ponziContract, activeUser, state, setSnackBarOpen, setSnackbarMessage, addTransaction, fiatCcy, ethConvRates);
        if (refresh>10000000) {
            setRefresh(0);
        }
        else {
            setRefresh(refresh+1);
        }
      }
      const stopPlayTrades = () => {setPlayRandomTrades(false)}
      const fastPlayTrades = () => {setPlayRandomTrades(true)}

      const handleActiveUserChange = (event, newUser) => {
        if (newUser===activeUser || !newUser) return;
          ponziContract.provider.setConnectedUser(newUser);
          setActiveUser(newUser);
      }      

      const tunerOpen = Boolean(tunerAnchor);
      const id = tunerOpen ? 'tuner-popover' : undefined;
      const handleTunerButtonClick = (event) => {
          setTunerAnchor(event.currentTarget);
      }
      const handleTunerClose = () => {
          setTunerAnchor(null);
      }

      const handlerTradeIntervalChange = (event) => {
        
        let intv = cleanInteger(event.target.value);
        if (!intv || intv==='') intv='0';
        intv=parseFloat(intv);
        setTradeIntervalMs(parseFloat(intv));
      }
      const validateTradeIntervalMs = () => {
          if (tradeIntervalMs<10) setTradeIntervalMs(10);
          else if (tradeIntervalMs>3600000) setTradeIntervalMs(3600000);
      }      
      
      return (
            <>                        

              <IconButton onClick={stopPlayTrades} size="large" color="primary" disabled={!playRandomTrades}>
                  <StopRounded size="large"/>
              </IconButton>
              <Tooltip title="Play a single random trade">
              <IconButton onClick={playTrade} size="large" color="primary" disabled={playRandomTrades}>
                  <PlayArrowRounded size="large"/>
              </IconButton>
              </Tooltip>
              <Tooltip title="Play multiple random trades">
                <IconButton onClick={fastPlayTrades} size="large" color="primary" disabled={playRandomTrades}>
                    <FastForwardRounded size="large"/>
                </IconButton>
              </Tooltip>
              <ToggleButtonGroup value={activeUser} exclusive onChange={handleActiveUserChange} aria-label="Connected User">
                  
                  <ToggleButton value="user" color="primary">
                      <PersonRoundedIcon color="primary"/>
                  </ToggleButton>
                  
                  
                  <ToggleButton value="others" color="primary">
                      <PeopleAltRoundedIcon color="primary" />
                  </ToggleButton>
                  
              </ToggleButtonGroup>  
              <IconButton size="large" color="primary" onClick={handleTunerButtonClick}>
                  <Tune/>
              </IconButton>          
              <Popover id="tune-simulation" open={tunerOpen} anchorEl={tunerAnchor} 
                onClose={handleTunerClose}  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}>
                 <Box maxWidth="sm" sx={{marginY:2, marginX:2, textAlign:'left', justifyContent:'left'}}>   
                 <Stack>
                     <Typography variant="p" component="p">Buy/Sell Probability in Random Trade</Typography>
                     <Box sx={{marginY:1, flexDirection:"row"}} maxWidth="sm" display="flex">
                     Buy {buySellProb}%&nbsp;<Slider value={buySellProb} onChange={(e, v)=>setBuySellProb(v)}/>&nbsp;Sell {100-buySellProb}%
                     </Box>
                     <Divider/>
                     <Box maxWidth="sm" sx={{marginY:2, textAlign:'left', justifyContent:'left'}}> 
                     <Box sx={{marginY:1, flexDirection:"row"}} maxWidth="sm" display="flex">
                     <FastForwardRounded/>
                     <Typography variant="p" component="p">
                         
                         Play Random Trades Every</Typography>
                         </Box>                         
                     <OutlinedInput id="tradeInterval"
                        value={tradeIntervalMs}
                        onChange={handlerTradeIntervalChange}            
                        endAdornment={<InputAdornment position="start">ms</InputAdornment>}                  
                        label="Trade Interval"
                        inputProps={{ inputMode: 'numberic', pattern: '[0-9]*' }}                        
                        onFocus = {event=>event.target.select()}
                        onBlur = {validateTradeIntervalMs}
                        fullWidth={false} />
                    </Box>
                     
                 </Stack>
                 </Box>
              </Popover>
            </>
      )
  }
  function OpenTab({openTab, fiatCcy, ponziContract, setPonziContract, isIdle, activeUser, refresh, transactions, setTransactions}) {
    if (!ponziContract) return;
    
    switch (openTab) {
        case "trade":
            return (<Trade fiatCcy={fiatCcy} ponziContract={ponziContract} setPonziContract={setPonziContract} isIdle={isIdle} refreshCounter={refresh} 
                    transactions={transactions} setTransactions={setTransactions} />)   
        case "tradeStats":
            return (<TradeStats fiatCcy={fiatCcy} ponziContract={ponziContract} setPonziContract={setPonziContract} isIdle={isIdle} activeUser={activeUser} 
                refreshCounter={refresh} transactions={transactions} setTransactions={setTransactions} />);
    }    
  }

  function HelpDialog({ponziContract, helpOpen, setHelpOpen}) {    
    if (!helpOpen) return;

    return (
        <Dialog open={helpOpen}         
        onClose={()=>setHelpOpen(false)}
        aria-labelledby="txn-dialog-title"
        aria-describedby="txn-dialog-description"
        sx={{padding:0, marginX:0}}>
        
        <DialogTitle id="txn-dialog-title">
            <SpeedRounded size="large"/> Welcome to the Simulator
        </DialogTitle>
        <DialogContent sx={{padding:0, marginX:0}}>    
            <Table>
                <TableBody>
                    <TableHead>
                        <TableRow>
                        <TableCell style={{width:'5%'}}></TableCell>
                        <TableCell style={{width:'95%'}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                        Use the simulator to see how your profits go up as others trade. The best strategy in this ponzi scheme is to buy and hold!
                        There are a lot of buttons on this screen but its easy to get started - just hit the play button
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <PersonRoundedIcon/>
                        </TableCell>
                        <TableCell>
                            Choose this to trade as yourself
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><PeopleAltRoundedIcon/></TableCell>
                        <TableCell>See how other peoples' trades affect your profit. Choose this to place trades as other people.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <TrendingUpRounded/>
                        </TableCell>
                        <TableCell>
                            Use the Trade tab to enter a trade manually (as yourself or other people)
                        </TableCell>                        
                    </TableRow>
                    <TableRow>
                        <TableCell><PlayArrowRounded/></TableCell>
                        <TableCell>Press this to place a single random trade (as yourself or other people)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><FastForwardRounded/></TableCell>
                        <TableCell>Press this to fast play multiple random trades (as yourself or other people)</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><StopRounded/></TableCell>
                        <TableCell>Stop placing multiple trades</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><TuneRounded/></TableCell>
                        <TableCell>Use this to tune the frequency of fast play trades and the probability of buy/sell on a random trade</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <AnalyticsRounded/>
                        </TableCell>
                        <TableCell>
                            Use the Stats tab to see your performance, contract stats and your transactions.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell/>
                        <TableCell>Start all over again by pressing the reset button</TableCell>
                    </TableRow>

                </TableBody>
            </Table>            
        </DialogContent>
        </Dialog>
    );
  }