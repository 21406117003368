import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { FormControl, FormControlLabel, MenuItem, Select, Switch } from "@mui/material";
import CoinLogo from "../assets/pnzcoin.png";
//import {connectProvider} from "../utils/ponziContract";
/*
let provider=null;
let signer=null;
async function connectProvider() {

    }
*/

export default function TopBar({fiatCcy, ponziContract, handleFiatCcyChange, toggleDrawer, toggleTheme, theme}) {

  const [sym, setSym] = useState('PNZ');

  React.useEffect(()=>{
    const f = async ()=> {
      if (!ponziContract || !ponziContract.provider) return;
      try {
        await ponziContract.connected;
        setSym(ponziContract.sym);
      }
      catch (error) {
        console.log("Error in top bar - "+error);
        return;
      }
    }
    f();
    return ()=>{};
    
  }, [ponziContract])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" title={<img width="36" height="36" src="pnzcoin.png" style={{marginTop:3}} alt="Coin Logo"/>}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
            
            <MenuIcon />                        
          </IconButton>
          

          <Typography variant="h6" component="h6" sx={{ fontFamily:'courier, courier new', fontSize:18, fontWeight:'bold', flexGrow: 1, alignItems:"center"}}>   
          <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            Ponzi&nbsp;   
            <Box component="img" sx={{height:36, width:36}} alt="Coin Logo" src={CoinLogo}/>                                   
            </div>              
            
          </Typography>
          
          
          
          
          
          
          <FormControl sx={{ m: 1, marginRight:2}} size="small">
                <Select
                    id="fiat-selector"
                    value={fiatCcy}
                    onChange={handleFiatCcyChange}
                >
                    <MenuItem value="AED">AED</MenuItem>
                    <MenuItem value="AUD">AUD</MenuItem>
                    <MenuItem value="BRL">BRL</MenuItem>
                    <MenuItem value="CAD">CAD</MenuItem>
                    <MenuItem value="CHF">CHF</MenuItem>
                    <MenuItem value="CNY">CNY</MenuItem>
                    <MenuItem value="DKK">DKK</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                    <MenuItem value="HKD">HKD</MenuItem>
                    <MenuItem value="INR">INR</MenuItem>
                    <MenuItem value="JPY">JPY</MenuItem>
                    <MenuItem value="KRW">KRW</MenuItem>
                    <MenuItem value="MXN">MXN</MenuItem>
                    <MenuItem value="NOK">NOK</MenuItem>
                    <MenuItem value="NZD">NZD</MenuItem>
                    <MenuItem value="PLN">PLN</MenuItem>
                    <MenuItem value="SEK">SEK</MenuItem>
                    <MenuItem value="THB">THB</MenuItem>
                    <MenuItem value="TRY">TRY</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                </Select>                 
            </FormControl> 
          <FormControlLabel sx={{marginTop:1}}
            control = { <Switch checked={theme==='dark'} onChange={toggleTheme} inputProps={{ 'aria-label': 'controlled' }}/> }
            label = "Dark"
          />
          
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}
