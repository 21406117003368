
import axios from "axios";
import { PonziSimulatorContract } from "./ponziSimulatorContract";
import { PonziSimulatorProvider } from "./ponziSimulatorProvider";

const { ethers, utils } = require("ethers");

//bigcoin
export class PonziContract {

    
    static defaultNodeUrl="https://bsc-dataseed.binance.org/"; //"https://data-seed-prebsc-1-s1.binance.org:8545/";
    static ccy="BNB";
    static infuraId="40155b0e3b164471a2122030d5995f2a";
    //static rinkebyAddr="0xa0f8E162fE706ed7B8e84F3B512D23e4a5c2D4b5";
    //static ganacheAddr="0xa0f8E162fE706ed7B8e84F3B512D23e4a5c2D4b5";
    //static goerliAddr="0xB7E1a5F1c88d96eA9A6076C144dDE0b3aB8966C7";

    //static contractAddr=this.rinkebyAddr;  
    static sym="PNZ";
    static abi=require("./ponzicoin.json");
    

    static networkDict = {
        rinkeby:{
            contractAddr:"0xc5f549F7F09814F17932CDB886ac6Db1dFC7Bb17",
            ccy:"ETH",
            etherscan:"https://rinkeby.etherscan.io/",

        },
        ganache:{
            contractAddr:"0xa0f8E162fE706ed7B8e84F3B512D23e4a5c2D4b5",
            ccy:"ETH",
            etherscan:""
        },
        goerli:{
            contractAddr:"0x4930C3EF5a9F89ECAd218dB0228498d780b0addb",
            ccy:"ETH",
            etherscan:"https://goerli.etherscan.io/"
        },
        ropsten:{
            contractAddr:"0x7fBf48c97B616b6B6B9406585BF6eBBE72e48C67",
            ccy:"ETH",
            etherscan:"https://ropsten.etherscan.io/"            
        },
        bnbt:{
            contractAddr:"0x203B1925C57a603eDB9A881DfD2B3f0e388E4415",
            ccy:"BNB",
            etherscan:"https://testnet.bscscan.com/"
        },
        simulator:{
            contractAddr:PonziSimulatorProvider.contractAddr,
            ccy:"BNB",
            etherscan:""
        },
        bnb:{
            contractAddr:"0x65b972d4469F7386cCb45997d8485b34A4Fa0faC",
            ccy:"BNB",
            etherscan:"https://bscscan.com/"
        }
    }

    constructor(provider, simulator=false) {
        //if (!provider && !simulator) {
        //    provider = new ethers.providers.JsonRpcProvider(PonziContract.defaultNodeUrl);
       // }
        ethers.logger.info("Creating new contract");
        if (!provider && !window.ethereum && !simulator) {
            provider = new ethers.providers.JsonRpcProvider(PonziContract.defaultNodeUrl);
        }
        this.simulator=simulator;
        this.provider=provider;

        this.contract=null;   
        this.isConnected = false; 
        this.signerAddr=null;
        this.hasSigner = false;
        this.network = null;
        this.ccy = PonziContract.ccy;
        this.sym=PonziContract.sym;
      
        if (provider) {
            this.connected = this.connectContract();
        } 
        else {
            this.connected  = 
            new Promise((resolve, reject) => {                                
                reject("No provider connected yet");
            });              
        }
        this.setMessage = null; 
        this.transactionIdx=0; 
        this.transactions = [];
        this.initPrice={wei:ethers.BigNumber.from(0), ether:'0'};
        this.decimals=(ethers.BigNumber.from(10)).pow(18);
        this.spreadBps = 0;
        this.spreadText = 'N/A';        
    }

    static formatNumber(str, maxLength) {
        let decimalLoc = str.indexOf(".");
        if (decimalLoc>maxLength) {
            maxLength=decimalLoc;
        }
        let ret = str.substring(0, maxLength);
        if (ret.indexOf('.')>0 && ret.length<maxLength) {
            ret=ret.padEnd(maxLength, '0');
        }
        return ret;
    }

    async connectContract() {
        if (this.provider!==null) {  
            await this.provider.ready;
            let net = await this.provider.getNetwork();
            console.log("net.name="+net.name);            
            let netInfo=PonziContract.networkDict[net.name];                        
            if (!netInfo) {
                ethers.logger.info("Network not supported - "+net.name);
                return new Promise((resolve, reject) => {
                    reject("Network not supported - "+net.name+". Please reconnect your wallet to BNB Smart Chain");
                }); ;
            }   
            this.network = netInfo;
            this.network['network']=net;
            this.network['name']=net.name;
            this.ccy=netInfo['ccy'];
            if (net.name==="simulator") {
                this.contract = new PonziSimulatorContract(this.provider);
            }
            else {
                this.contract = new ethers.Contract(netInfo.contractAddr, PonziContract.abi.abi, this.provider);  
            }
            this.contractEtherscanUrl=netInfo.etherScan+"/address/"+netInfo.contractUrl;
            this.codeUrl=this.contractEtherscanUrl+"#code";

            //this.poolContributionBps = await this.contract.POOL_CONTRIBUTION_BPS(); 
            this.sym = await this.contract.symbol();
            this.decimals = await this.contract.DECIMALS();
            this.criticalSupplyLevel = await this.contract.CRITICAL_SUPPLY_LEVEL(); 
            this.buyPriceWei = await this.contract.getBuyPrice();
            this.sellPriceWei = await this.contract.getSellPrice();    
            this.spreadBps = await this.contract.SPREAD_BPS();
            this.spreadText = (parseFloat(this.spreadBps.toString())/100).toFixed(2)+"%";
            this.minPriceWei = await this.contract.MIN_PRICE();
            this.isConnected=true;    
            try {
                this.signerAddr=await this.provider.getSigner().getAddress();
                console.log(net.name+" signer = "+this.signerAddr);
                this.hasSigner = (net.name==='simulator') || (utils.isAddress(this.signerAddr));   
                ethers.logger.info("Signer connected");             
                //ethers.logger.info("Signer = "+await this.provider.getSigner().getAddress());
            }
            catch (e) {
                ethers.logger.info("No signer - "+e.toString());
            }
            ethers.logger.info("Contract connected - "+netInfo.contractAddr);                           
        }
        else {
            console.log("Provider not provided");
        }
    }


    static formatEther(wei) {
        return parseFloat(utils.formatEther(wei));
    }

    async getBuyPrice(setBuyPrice) {
        if (!this.provider) return;    
        try {
            await this.connected;        
        }
        catch (e) {
            ethers.logger.info("No connection yet (buyPrice)");
            return;
        }
        if (!this.isConnected) return;
        this.contract.getBuyPrice().then((p)=>{
            this.buyPriceWei = p;
            setBuyPrice({
                wei:p,
                ether:utils.formatEther(p),
                time:new Date()
            });
        }).catch((e)=> {
            console.log(e);
            if (e.toString().includes("underlying network changed")) {
                window.location.reload();
            }
        });
    }

    async getSellPrice(setSellPrice) {
        if (!this.provider) return; 
        try {
            await this.connected;  
        }
        catch (e) {
            ethers.logger.info("No provider yet");
            return;
        }
        if (!this.isConnected) return;           
        this.contract.getSellPrice().then((p)=>{
            this.sellPriceWei = p;
            setSellPrice({
                wei:p,
                ether:utils.formatEther(p),
                time:new Date()
            });
        }).catch((e)=> {
            console.log(e);
            if (e.toString().includes("underlying network changed")) {
                window.location.reload();
            }
        });
    }

    getTokensFromValue(side, buyPrice, sellPrice, valueEther, setTokens) {        
        if (!this.isConnected) return;        
        let valueWei =  utils.parseEther(valueEther);  
        //let poolContribBps = this.poolContributionBps; //*((side==='buy') ? -1 : 1);      
        
        let netValueWei = valueWei;
        /*if (side=='buy') {
            netValueWei = valueWei.mul(10000-poolContribBps).div(10000);
        }
        else {                          
            netValueWei = valueWei.mul(10000).div(10000-poolContribBps);      
        }*/
        
        let tokensWei = (netValueWei.mul(this.decimals)).div((side==='buy') ? buyPrice.wei : sellPrice.wei);
        this.tokensWei=tokensWei;
        this.valueWei=valueWei;        
        let tokensEther = PonziContract.formatNumber(utils.formatEther(tokensWei), 15);

        //tokens=Math.round(tokens*100000)/100000;                
        setTokens({
            wei:tokensWei,
            ether:tokensEther
        });
    }

    getValueFromTokens(side, buyPrice, sellPrice, tokensEther, setValue) {        
        if (!this.isConnected) return;
        let tokensWei = utils.parseEther(tokensEther);
        let valueWei = tokensWei.mul((side==='buy') ? buyPrice.wei : sellPrice.wei).div(this.decimals);        
        //let poolContribBps = this.poolContributionBps; //*((side==='buy') ? 1 : -1); 
        
        let netValueWei=valueWei;
        /*if (side=='buy') {
            netValueWei = valueWei.mul(10000).div(10000-poolContribBps);
        }
        else {
            netValueWei = valueWei.mul(10000-poolContribBps).div(10000);
        }*/
        this.tokensWei = tokensWei;
        this.valueWei = netValueWei;
        let numDecimals=(netValueWei>0) ? 7 : 0;
        let dispValue = parseFloat(utils.formatEther(netValueWei)).toFixed(numDecimals);
        //dispValue =  Math.round(dispValue*1000000)/1000000;          
        setValue({
            wei:netValueWei,
            ether:dispValue
        });
    }

    
    async getSignerEtherBalance(setEtherBalance) {
        if (!this.provider || !this.hasSigner) return;
        let bal;
        try {
            let signer=this.provider.getSigner(); 
            
            if (signer) {
                let signerAddr = await signer.getAddress();           
                let balWei = await this.provider.getBalance(signerAddr);                
                let balEther = utils.formatEther(balWei);
                bal = {wei:balWei, ether:balEther, time:new Date()};
                setEtherBalance(bal);
            }
            else {
                setEtherBalance(this.initPrice);
            }
        }
        catch (error) {
            if (error.toString().includes("underlying network changed")) {
                window.location.reload();
            }                

            console.log(error);
            setEtherBalance(this.initPrice);
        }
    }

    async getTokenBalance(address, setTokenBalance) {
        if (!this.provider) return;        
        try {
            await this.connected;
        }
        catch (e) {
            ethers.logger.info("Not connected yet - "+e.toString());
            return;
        }
        if (!this.isConnected) return;

        try
        {
            if (!utils.isAddress(address)) {
                address = await this.provider.resolveName(address);
                if (!address) {
                    console.log("Invalid address: "+address);
                    return;
                }
            }
            let tokensWei = await this.contract.balanceOf(address);
            let tokensEther = utils.formatEther(tokensWei);
            setTokenBalance({wei:tokensWei, ether:tokensEther, time:new Date()});
        }
        catch (error) {            
            if (error.toString().includes("underlying network changed")) {
                window.location.reload();
            }
            console.log(error);
            setTokenBalance(this.initPrice);
        }        
    }

    async getSignerTokenBalance(setTokenBalance) {
        if (!this.provider) return;        
        try {
            await this.connected;
        }
        catch (e) {
            ethers.logger.info("Not connected yet - "+e.toString());
            return;
        }
        if (!this.isConnected || !this.hasSigner) return;

        try
        {
            let signer=this.provider.getSigner();  
            
            if (signer) {
                let signerAddr=await signer.getAddress();                
                let tokensWei = await this.contract.balanceOf(signerAddr);
                let tokensEther = await utils.formatEther(tokensWei);
                setTokenBalance({wei:tokensWei, ether:tokensEther, time:new Date()});
               
            }
            else {
                setTokenBalance(this.initPrice);
            }       
        }
        catch (error) {            
            if (error.toString().includes("underlying network changed")) {
                window.location.reload();
            }
            console.log(error);
            setTokenBalance(this.initPrice);
        }
    }

    setMessageMethod(setMessage) {
        this.setMessage = setMessage;
    }

    static getRandomId() {
        return (1+Math.random()*1000000000).toFixed(2);
    }

    async transactTokens(side, amount, tokens, buyPrice, sellPrice, slippageBps, setTokenBalance, setEtherBalance, setMessage, setBuyPrice, setSellPrice, 
        ethRates, fiatCcy, addTransaction=null) {
        
        if (!this.isConnected || !this.hasSigner) {
            setMessage("Error: Not Connected to Wallet and/or Contract");            
            return;
        }
        let signer = this.provider.getSigner();
        if (!signer) setMessage("Error: Not connected to wallet - no accounts available to sign transaction");

        let signerAddr=await signer.getAddress();
        let txnText = (side==='buy') ? "(Buy "+this.ccy+ " "+PonziContract.formatNumber(utils.formatEther(amount.wei), 8)+" worth of PNZ)" :
                "(Sell PNZ "+PonziContract.formatNumber(utils.formatEther(tokens.wei), 8)+" for "+this.ccy+")";
                
        if (side==='buy') {
            if (amount.wei.eq(0)) {
                setMessage("Error: Amount not specified");
                return;
            }            
            let ccyBalance = await this.provider.getBalance(signerAddr);
            if (ccyBalance.lt(amount.wei)) {
                ethers.logger.warn("Attempting to buy "+amount.wei+", but balance is "+ccyBalance);
                setMessage("Error: Insufficient funds. Your "+this.ccy+" balance is "+PonziContract.formatNumber(utils.formatEther(ccyBalance), 10));
                return;
            }
        }
        if (side==='sell') {            
            let totalTokenSupply = await this.contract.totalSupply();
            let userTotalTokens = await this.contract.balanceOf(signerAddr);

            if (tokens.wei.eq(0)) {
                setMessage("Error: Tokens not specified");
                return;
            }

            if (userTotalTokens.lt(tokens.wei)) {
                ethers.logger.warn("Attempting to sell "+tokens.wei+" tokens, but user balance is "+userTotalTokens);  
                setMessage("Error: Insufficient tokens. Your PNZ balance is "+PonziContract.formatEther(userTotalTokens).toFixed(6));  
                return;            
            }
            
            if ((totalTokenSupply.sub(tokens.wei)).lt(this.criticalSupplyLevel)) {
                ethers.logger.warn("Selling "+
                    tokens.wei+
                    " PNZ would bring token supply="+totalTokenSupply+" below critical level="+this.criticalSupplyLevel);

                setMessage("Error: Selling "+
                    PonziContract.formatEther(tokens.wei).toFixed(5)+
                    " PNZ would bring contract token supply ("+
                    PonziContract.formatEther(totalTokenSupply).toFixed(2)+") below critical level ("+
                    PonziContract.formatEther(this.criticalSupplyLevel).toFixed(2)+")");                    
                return;
            }
        }

        setMessage("Confirm transaction "+txnText);

        let rwContract=this.contract.connect(signer);        
        
        let tx;
        let eventName;
        let pastTenseSide;

        
        

        ethers.logger.info("Attempting "+side+": sender="+signerAddr+", amount="+amount.wei+", tokens="+tokens.wei);
        ethers.logger.info("Buy="+buyPrice+", Sell="+sellPrice+", SlippageBps="+slippageBps);        
        let txn={id: PonziContract.getRandomId(), sentTime:new Date(), sender:signerAddr, side:side, sentVal:amount.wei, sentTokens:tokens.wei, slippageBps:slippageBps};
        try {
            if (side==='buy') {        
                txn['sentPrice']=buyPrice;        
                tx= await rwContract.safeMint(buyPrice, slippageBps, {value: amount.wei});
                //tx= await rwContract.safeMint(utils.parseUnits('0.00001'), slippageBps, {value: amount.wei});
                eventName='PonziMint';
                pastTenseSide='bought';            
            }        
            else {             
                txn['sentPrice']=sellPrice;                 
                tx=await rwContract.safeBurn(sellPrice, slippageBps, tokens.wei);                
                eventName='PonziBurn';
                pastTenseSide="sold";            
            }            
            setMessage("Pending: Awaiting transaction response "+txnText);
            let res=await tx.wait();
            let ev = res.events.find(event => event.event===eventName);
            let [sender, numTokens, price, val] = ev.args; 
            ethers.logger.info("Confirmed. Sender: "+sender+", tokens="+numTokens+", price="+price+", val="+val+", side="+side);  
            txn['recdTime']=new Date(); 
            txn['recdTokens']=numTokens;
            txn['recdPrice']=price;
            txn['recdVal']=val;
            txn['fiatCcy']=fiatCcy;
            txn['fiatVal']=((Object.keys(ethRates).length>0) ? (ethRates.rates[fiatCcy]*parseFloat(utils.formatEther(val))) : 0)
            txn['hash']=tx.hash;
            setMessage("Completed! You "+pastTenseSide+" "+PonziContract.formatEther(numTokens).toFixed(5)+" PNZ for "+
                this.ccy+" "+PonziContract.formatEther(val).toFixed(5));                                         
        }
        catch (e) {                                 
            this.processTransactionError(e, setMessage, txn);
            return;
        }                
        if (addTransaction) {
            addTransaction(txn);
        }
        this.getBuyPrice(setBuyPrice);
        this.getSellPrice(setSellPrice);
        this.getSignerEtherBalance(setEtherBalance);
        this.getSignerTokenBalance(setTokenBalance, setMessage);
                       
    }

    processTransactionError(e, setMessage, txn) {
        console.log(e);            
        ethers.logger.info("Error sending transaction - "+e.message);
        txn['recdTime']=new Date();
        if (e.reason) {
            txn['error']=e.reason;
            setMessage("Error: "+e.reason);
        }
        else if (e.message) {
            txn['error']=e.message;
            let strE=e.message;                
            if (strE.includes("transaction was replaced")) {
                setMessage("Warning: Transaction was replaced - unable to track");
            }
            else if (strE.includes("Slippage limit exceeded")) {                                   
                setMessage("Error: Slippage limit exceeded. Please refresh prices");
            }
            else if (strE.includes("User denied transaction")) {
                setMessage("Error: You rejected this transaction");
            }
            else {
                setMessage("Error processing transaction");
            }                
        }
        else {        
            txn['error']=e.toString();        
            setMessage("Error processing transaction");
        }            
    }

    async transferTokens(tokens, toAddress, setTokenBalance, setToTokenBalance, setMessage, ethConvRates, fiatCcy, addTransaction=null) {
        if (!this.isConnected || !this.hasSigner) {
            setMessage("Error: Not Connected to Wallet and/or Contract");            
            return;
        }
        let signer = this.provider.getSigner();
        if (!signer) setMessage("Error: Not connected to wallet - no accounts available to sign transaction");

        let signerAddr=await signer.getAddress();

        if (!tokens.gt(0)) {
            setMessage("Error: Please specify a valid number of tokens to transfer");
            return;
        }

        if (!toAddress || toAddress.trim()==='') {
            setMessage("Error: Please enter a to address");
            return;
        }

        if (!utils.isAddress(toAddress.trim())) {
            toAddress = await this.provider.resolveName(toAddress);
            if (!toAddress) {
                setMessage("Error: Invalid to address");
                return;
            }
        }
        

        if (toAddress===signerAddr) {
            setMessage("Error: Cannot transfer tokens to yourself");
            return;
        }        

        let signerTokens;
        try {
            signerTokens=await this.contract.balanceOf(signerAddr);
        }
        catch (e) {
            console.log(e);
            setMessage("Error: Unable to get your balance.");
            return;
        }
        if (tokens.gt(signerTokens)) {
            setMessage("Error: Cannot transfer "+this.sym+" "+PonziContract.formatNumber(utils.formatEther(tokens), 8)+". Your balance is "+
                this.sym+" "+PonziContract.formatNumber(utils.formatEther(signerTokens), 8));
            return;
        }        
        let addr;
        try {
            addr=utils.getAddress(toAddress);
            
        }
        catch (e) {
            console.log(e);
            setMessage("Error: Invalid to address");
            return;
        }


        let txn={};
        txn['id']=PonziContract.getRandomId();        
        txn['sentTime'] = new Date();
        txn['sender'] = signerAddr;
        txn['sentTokens'] = tokens;
        txn['receiver'] = addr;
        txn['side']='transfer';

        let tokenWorth = await this.getTokenWorth();
        txn['sentPrice'] = tokenWorth;

        let val = (tokenWorth.mul(tokens)).div(this.decimals);
        let fiatVal = ((Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*parseFloat(utils.formatEther(val))) : 0);

        let txnText="Transfer "+this.sym+" "+PonziContract.formatNumber(utils.formatEther(tokens), 8)+" to "+addr+
            " ("+this.ccy+" "+PonziContract.formatNumber(utils.formatEther(val), 8)+" / "+fiatCcy+" "+fiatVal.toFixed(4)+")";

        ethers.logger.info("Confirm: Transfer from:"+signerAddr+", to: "+addr+", tokens: "+tokens);            
        setMessage("Confirm: "+txnText);
        try {
            let rwContract=this.contract.connect(signer); 
            let gasLimit = await rwContract.estimateGas.transfer(addr, tokens);
            
            gasLimit=gasLimit.add(1000);
            
            let gasPrice = await this.provider.getGasPrice();
            ethers.logger.info("gasLimit = "+gasLimit);
            ethers.logger.info("gasPrice = "+gasPrice);
            let tx=await rwContract.transfer(addr, tokens, {gasLimit:gasLimit, gasPrice:gasPrice});
            setMessage("Pending: Awaiting transaction response "+txnText);
            ethers.logger.info("Awaiting "+tx.hash);

            let res=await tx.wait();
            let ev = res.events.find(event => event.event==='Transfer');
            let [sender, receiver, recdTokens] = ev.args; 
            setMessage("Completed! Transferred "+PonziContract.formatNumber(utils.formatEther(recdTokens), 8) +" to "+receiver);
            ethers.logger.info("Confirmed. From: "+sender+", to: "+receiver+", tokens="+recdTokens);
            txn['recdTime']=new Date();
            txn['recdTokens']=recdTokens;
            txn['recdPrice']=tokenWorth;
            txn['receiver']=receiver; 
            txn['fiatCcy']=fiatCcy;            
            txn['fiatVal']=fiatVal;
            txn['hash']=tx.hash;

        }
        catch (e) {
            this.processTransactionError(e, setMessage, txn);
        }

        if (addTransaction) {
            addTransaction(txn);
        }
        this.getSignerTokenBalance(setTokenBalance, setMessage);  
        this.getTokenBalance(addr, setToTokenBalance);  

    }

    async getTokenWorth() {
        let balanceWei = await this.provider.getBalance(this.network.contractAddr);
        let tokenSupplyWei = await this.contract.totalSupply();
        let tokenWorth = (balanceWei.mul(this.decimals)).div(tokenSupplyWei);
        return tokenWorth;

    }
    async getContractBalance(setContractBalance) {
        if (!this.provider) {
            return;
        }
        try {
            await this.connected;
        }
        catch (e) {
            ethers.logger.info("No connection yet - getContractBalance");
            return;
        }

        if (!this.isConnected) {
            ethers.logger.info("Not connected to contract");
            return;
        }

        let balanceWei = await this.provider.getBalance(this.network.contractAddr);
        let balanceEther = utils.formatEther(balanceWei);
        setContractBalance({
            time:new Date(),
            wei:balanceWei,
            ether:balanceEther
        });
    }

    async getTokenSupply(setTokenSupply) {
        if (!this.provider) {
            return;
        }
        try {
            await this.connected;
        }
        catch (e) {
            ethers.logger.info("No connecion yet - getTokenSupply()");
            return;
        }

        if (!this.isConnected) {
            ethers.logger.info("getTokenSupply - not connected to contract");
            return;
        }

        let tokenSupplyWei = await this.contract.totalSupply();
        let tokenSupplyEther = utils.formatEther(tokenSupplyWei);

        setTokenSupply({
            time:new Date(),
            wei:tokenSupplyWei,
            ether:tokenSupplyEther
        });
    }

    async getSignerBuyAmount(setMyBuyAmount) {
        if (!this.provider) return;        
        try {
            await this.connected;
        }
        catch (e) {
            ethers.logger.info("Not connected yet getSignerBuyAmount() - "+e.toString());
            return;
        }
        if (!this.isConnected || !this.hasSigner) return;

        try
        {
            let signer=this.provider.getSigner();  
            if (signer) {
                let valueWei = await this.contract.getMintValue(await signer.getAddress());
                let valueEther = utils.formatEther(valueWei);
                setMyBuyAmount({wei:valueWei, ether:valueEther, time:new Date()});
               
            }
            else {
                setMyBuyAmount(this.initPrice);
            }       
        }
        catch (error) {            
            if (error.toString().includes("underlying network changed")) {
                window.location.reload();
            }
            console.log(error);
            setMyBuyAmount(this.initPrice);
        }
    }

    async getSignerSellAmount(setMySellAmount) {
        if (!this.provider) return;        
        try {
            await this.connected;
        }
        catch (e) {
            ethers.logger.info("Not connected yet (getSignerSellAmount) - "+e.toString());
            return;
        }
        if (!this.isConnected || !this.hasSigner) return;
        
        try
        {
            let signer=this.provider.getSigner();  
            if (signer) {
                let valueWei = await this.contract.getBurnValue(await signer.getAddress());
                let valueEther = utils.formatEther(valueWei);
                setMySellAmount({wei:valueWei, ether:valueEther, time:new Date()});
               
            }
            else {
                setMySellAmount(this.initPrice);
            }       
        }
        catch (error) {            
            if (error.toString().includes("underlying network changed")) {
                window.location.reload();
            }
            console.log(error);
            setMySellAmount(this.initPrice);
        }
    }    

    
    async getEthConvRates(setEthConvRates) {
        try
        {            
            let ethConvUrl='https://api.coinbase.com/v2/exchange-rates?currency='+this.ccy;
            axios.get(ethConvUrl).then((r)=>setEthConvRates(r.data.data));
        }
        catch (e) {
            ethers.logger.info("Error in fetchEthConvRates");
            ethers.logger.info(e);
        }

    }


}