import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { useState } from "react";
import Container from "@mui/material/Container";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, List, ListItem, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Tooltip, Typography} from '@mui/material';
//import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ethers, utils } from 'ethers';
import { PonziContract } from '../utils/ponziContract2';
import RefreshOutlinedIcon  from '@mui/icons-material/RefreshOutlined';
import HighlightOffOutlinedIcon  from '@mui/icons-material/HighlightOffOutlined';
import { CircularProgress } from '@mui/material';
import { ContentCopyOutlined, ExpandMore, HelpOutlined, InfoOutlined, PeopleAltRounded, PersonRounded, WarningAmberOutlined } from '@mui/icons-material';
import WalletAlert from '../components/WalletAlert';
import {deserializeState, serializeState} from "../utils/utils";

const contractBalanceTheme = createTheme({
    palette: {
      background:"#7474aa",
      primary: {
        main: "#f5f5dc",
        light: "#f5f5dc",
        dark: "#f5f5dc"        
      }
    }
    
  });

const bidTheme = createTheme({
palette: {
    background:"#b22222",
    primary: {
    main: "#f5f5dc",
    light: "#f5f5dc",
    dark: "#f5f5dc"        
    }
}

});

const offerTheme = createTheme({
palette: {
    background:"#008000",
    primary: {
    main: "#f5f5dc",
    light: "#f5f5dc",
    dark: "#f5f5dc"
    }
}

});  

const pnlTheme = createTheme({
    palette: {
        background:"#b36b00",
        primary: {
        main: "#f5f5dc",
        light: "#f5f5dc",
        dark: "#f5f5dc"
        }
    }
});


const bigZero=ethers.BigNumber.from(0);
const initPrice = {wei:bigZero, ether:'0.0'};
const initTxnState = {txns:[]};

export default function TradeStats({fiatCcy, ponziContract, setPonziContract, isIdle, activeUser, refreshCounter, transactions, setTransactions}) {
    
    const [contractBalance, setContractBalance] = useState(initPrice);
    const [tokenSupply, setTokenSupply] = useState(initPrice);
    const [tokenWorth, setTokenWorth] = useState(initPrice);
    const [buyPrice, setBuyPrice] = useState(initPrice);
    const [sellPrice, setSellPrice] = useState(initPrice);    
    const [helpOpen, setHelpOpen] = useState(false);
    const [openAccordion, setOpenAccordion] = useState((ponziContract.hasSigner) ? 'myStats' : 'contractStats');
    const [myTokens, setMyTokens] = useState(initPrice);    
    const [myBuyAmount, setMyBuyAmount] = useState(initPrice);
    const [mySellAmount, setMySellAmount] = useState(initPrice);
    const [ethConvRates, setEthConvRates] = useState({});
    //const [transactions, setTransactions] = useSemiPersistentState('txns2', serializeState(initTxnState));
    //const [transactionsDeserialized, setTransactionsDeserialized] = useState(initTxnState);

    let txnsDeserialized = deserializeState(transactions);
    const refreshContractStats = async () => {
        if (ponziContract && ponziContract.provider && !isIdle) {      
            try {
                await ponziContract.connected;
            }
            catch (e) {
                ethers.logger.info("No connection yet - refreshContractStats()");
                return;
            }                  
            ponziContract.getContractBalance(setContractBalance);
            ponziContract.getTokenSupply(setTokenSupply);
            ponziContract.getBuyPrice(setBuyPrice);
            ponziContract.getSellPrice(setSellPrice);      
            ponziContract.getEthConvRates(setEthConvRates);         
            ponziContract.getSignerTokenBalance(setMyTokens);                       
        }
    }   

    const refreshMyStats = async () => {
        if (ponziContract && ponziContract.provider && !isIdle) {  
            try {
                await ponziContract.connected;
            }
            catch (e) {
                ethers.logger.info("No connection yet - refreshMyStats()");
                return;
            }
            if (!ponziContract.hasSigner) {
                return;
            }
            ponziContract.getSignerTokenBalance(setMyTokens);            
            ponziContract.getBuyPrice(setBuyPrice);
            ponziContract.getSellPrice(setSellPrice);  
            ponziContract.getEthConvRates(setEthConvRates);
            ponziContract.getSignerBuyAmount(setMyBuyAmount);
            ponziContract.getSignerSellAmount(setMySellAmount); 
            ponziContract.getContractBalance(setContractBalance);
            ponziContract.getTokenSupply(setTokenSupply);                         
        }
    }

    const refreshMyTransactions = async () => {
        //let txns = localStorage.getItem('txns2');
        //setTransactions(txns);
        //txnsDeserialized=deserializeState(txns);
        //setTransactionsDeserialized(deserializeState(txns));
        //console.log("Refreshing transactions");        
        //console.log(deserializeState(txns));        
    }

    React.useEffect(()=>{
        if (isIdle) return;
        let refresh=()=>{};        
        switch (openAccordion) {
            case 'contractStats':
                refresh=refreshContractStats;
                break;
            case 'myStats':
                refresh=refreshMyStats;
                break;
            case 'myTransactions':
                refresh=refreshMyTransactions;
                break;
        }        
        refresh();
        const interval = setInterval(refresh, 30000);
        return ()=>clearInterval(interval);
    }, [ponziContract, openAccordion, isIdle, activeUser, refreshCounter, transactions])

    
    React.useEffect(()=>{        
        if (tokenSupply.wei>0){
            let tokenWorthWei=(contractBalance.wei.mul(ponziContract.decimals)).div(tokenSupply.wei);            
            setTokenWorth({
                wei:tokenWorthWei,
                ether:utils.formatEther(tokenWorthWei),
                time:new Date()
            });
        }
    }, [contractBalance, tokenSupply]);

    return (
        <Container maxWidth="sm">
        <Accordion id="myStats" sx={{marginY:2}} expanded={openAccordion==='myStats'} onChange={(event, expanded)=>{
            setOpenAccordion((openAccordion==='myStats') ? '' : 'myStats');            
            }}>
            <AccordionSummary id="myStatsSumary" expandIcon={<ExpandMore/>}>
                <Typography variant="h6" component="h6" marginTop={0}>
                {((activeUser && activeUser==='others')? 'Others' : 'My')} Stats
 
                </Typography>
            </AccordionSummary>
            <AccordionDetails>                
            <Box sx={{marginY:0}} maxWidth="sm">                
            <Toolbar edge="start" sx={{ mr: 2, justifyContent:"right", alignContent:"right", alignItems:"right", marginX:0}}>
                {/*showSignerWarning(ponziContract)*/}
                <WalletAlert ponziContract={ponziContract} setPonziContract={setPonziContract} showButton={false} />
                <Tooltip title="Refresh">
                <IconButton id="myStatsRefresh" color="primary" size="large" onClick={()=>{
                    refreshMyStats();                    
                }}>
                    <RefreshOutlinedIcon id="myStatsRefreshIcon" size="large"/>
                </IconButton>  
                </Tooltip>
                &nbsp;
                <Tooltip title="Help">
                <IconButton id="myStatsHelp" color="primary" size="large" onClick={()=>{
                    setHelpOpen(true);                    
                    }}>                    
                    <HelpOutlined id="myStatsHelpIcon" size="large" />
                </IconButton> 
                </Tooltip>                                      
            </Toolbar>                
            <Grid container spacing={4}>
            {/*}
            <Grid item xs={6}>
            <ThemeProvider theme={contractBalanceTheme}>
            <Tooltip title={"Total "+ponziContract.ccy+" funds in my wallet"}>
                <Paper elevation={3} className='contractBalance'>   
                    
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>            
                    {(activeUser && activeUser==='others')? 'OTHERS' : "MY"} WALLET
                    </Typography>                          
                    <Typography variant="body1" component="p" sx={{color: 'primary.main'}} marginLeft={0.5} marginRight={0.5}>
                        {ponziContract.ccy} {PonziContract.formatNumber(myBalance.ether, 8)}
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*parseFloat(myBalance.ether)).toFixed(4) : ''}
                    </Typography>     
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        {(myBalance.time) ? myBalance.time.toLocaleString(): (ponziContract.hasSigner) ? <CircularProgress size={11}/> : <WarningAmberOutlined size={11}/>}
                    </Typography>                   
                </Paper>
            </Tooltip>
            </ThemeProvider>
            </Grid>
                */}
            <Grid item xs={12}>
            <ThemeProvider theme={contractBalanceTheme}>
            <Tooltip title={"Total number of "+ponziContract.sym+" tokens currently owned by me valued at the token worth (contract balance/total token supply)"}>
                <Paper elevation={3} className='contractBalance'>              
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>            
                    {(activeUser && activeUser==='others')? 'OTHERS' : "MY"} TOKENS
                    </Typography>
                    <Typography variant="body1" component="p" sx={{color: 'primary.main'}} marginLeft={0.5} marginRight={0.5}>
                        {ponziContract.sym} {PonziContract.formatNumber(myTokens.ether,8)}
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? 
                            (ethConvRates.rates[fiatCcy]*parseFloat(utils.formatEther((myTokens.wei.mul(tokenWorth.wei)).div(ponziContract.decimals)))).toFixed(4)                            
                            : ''}
                    </Typography>     
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        {(myTokens.time) ? myTokens.time.toLocaleString(): (ponziContract.hasSigner) ? <CircularProgress size={11}/> : <WarningAmberOutlined size={11}/>}
                    </Typography>                   
                </Paper>
            </Tooltip>
            </ThemeProvider>
            </Grid>        
            </Grid>            
        </Box>
        <Box sx={{marginY:3}} maxWidth="sm">
            <Grid container spacing={1}>
            <Grid item xs={4}>
            <ThemeProvider theme={bidTheme}>
                <Tooltip title={"Total price paid for all "+ponziContract.sym+" tokens ever minted by me. "+fiatCcy+" equivalent is at current prices and approximate"}>
                <Paper elevation={3} className='bid'>              
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5}>            
                        {ponziContract.sym} COST BASIS
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main', textAlign:'center', justifyContent:'center'}}>
                        {ponziContract.ccy} {PonziContract.formatNumber(myBuyAmount.ether,8)}                                    
                    </Typography>        
                    
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main', textAlign:'center', justifyContent:'center'}} marginBottom={0.2}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*parseFloat(myBuyAmount.ether)).toFixed(4) : ''}
                    </Typography>     
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main', textAlign:'center', justifyContent:'center'}} marginBottom={0.2}>
                        {(myBuyAmount.time) ? myBuyAmount.time.toLocaleString(): (ponziContract.hasSigner) ? <CircularProgress size={11}/> : <WarningAmberOutlined size={11}/>}
                    </Typography>                                    
                </Paper>
                </Tooltip>
                </ThemeProvider>
            
            </Grid>


            <Grid item xs={4}>
                <ThemeProvider theme={offerTheme}>
                <Tooltip title={"Total "+ponziContract.ccy+" value of all "+ponziContract.sym+" tokens ever burned by me. "+fiatCcy+" equivalent is at current prices and approximate."}>
                <Paper elevation={3} className='offer'>
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5}>            
                        REALIZED SALE
                    </Typography>            
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}}>
                        {ponziContract.ccy} {PonziContract.formatNumber(mySellAmount.ether, 8)}                    
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*mySellAmount.ether).toFixed(4) : ''}
                    </Typography>      
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        {(mySellAmount.time) ? mySellAmount.time.toLocaleString(): (ponziContract.hasSigner) ? <CircularProgress size={11}/> : <WarningAmberOutlined size={11}/>}
                    </Typography>                          
                </Paper>
                </Tooltip>
                </ThemeProvider>
            </Grid>

            <Grid item xs={4}>
                <ThemeProvider theme={contractBalanceTheme}>
                <Tooltip title={ponziContract.ccy+" value of my "+ponziContract.sym+" tokens at the current sell price. "+fiatCcy+" equivaluent is at current prices and approximate"}>
                <Paper elevation={3} className='contractBalance'>
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5}>            
                        UNREALIZED SALE
                    </Typography>            

                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}}>
                        {ponziContract.ccy} {PonziContract.formatNumber(
                                                utils.formatEther((myTokens.wei.mul(sellPrice.wei)).div(ponziContract.decimals)), 8)}                    
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ?
                            (ethConvRates.rates[fiatCcy]*parseFloat(utils.formatEther((myTokens.wei.mul(sellPrice.wei)).div(ponziContract.decimals)))).toFixed(4) 
                            : ''}                                                           
                    </Typography>                            
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        {(myTokens.time) ? myTokens.time.toLocaleString(): (ponziContract.hasSigner) ? <CircularProgress size={11}/> : <WarningAmberOutlined size={11}/>}
                    </Typography>                   
                </Paper>
                </Tooltip>
                </ThemeProvider>
            </Grid>            
            </Grid>
            </Box>
            
            <Box sx={{marginY:5}} maxWidth="sm">

                <ThemeProvider theme={pnlTheme}>
                <Tooltip title="The overall profit/loss of my past and present holdings. Calculated as ((Realized Sale+Unrealized Sale)-Cost Basis)/Cost Basis">
                <Paper elevation={3} className='pnl'>
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}}>
                        CURRENT PROFIT (REALIZED + UNREALIZED)
                    </Typography>
                    <Typography variant="body2" component="h3" sx={{color: 'primary.main'}}>
                        {getPnl(myBuyAmount, mySellAmount, myTokens, sellPrice, ponziContract, fiatCcy, ethConvRates).pct+"%"}

                    </Typography>
                    <Typography variant="body2" component="h3" sx={{color: 'primary.main'}}>
                        {ponziContract.ccy}&nbsp;{PonziContract.formatNumber(
                                getPnl(myBuyAmount, mySellAmount, myTokens, sellPrice, ponziContract, fiatCcy, ethConvRates).ether, 10)}
                    </Typography>
                    <Typography variant="body2" component="h3" sx={{color: 'primary.main'}}>
                        {fiatCcy}&nbsp;
                        {getPnl(myBuyAmount, mySellAmount, myTokens, sellPrice, ponziContract, fiatCcy, ethConvRates).fiat}
                    </Typography>                                        

                </Paper>
                </Tooltip>
                </ThemeProvider>
            </Box>            

        </AccordionDetails>

        </Accordion>              
        <Accordion id="contractStats" sx={{marginY:2}} expanded={openAccordion==='contractStats'} onChange={(event, expanded)=>{                        
                setOpenAccordion((openAccordion==='contractStats') ? '' : 'contractStats');            
            }}>
            <AccordionSummary id="contractStatsSumary" expandIcon={<ExpandMore/>}>
                <Typography variant="h6" component="h6" marginTop={0}>
                Contract Stats
                </Typography>
            </AccordionSummary>               
            <AccordionDetails>                                            
            <Box sx={{marginY:1}} maxWidth="sm">
            {(!ponziContract.simulator) ? 
            <Box sx={{marginY:1}} maxWidth="sm">
                <WalletAlert ponziContract={ponziContract} setPonziContract={setPonziContract} showButton={true}/>
                {(ponziContract.hasSigner) ? 
                <Typography variant="p" component="p">
                    To see more details about this contract&nbsp;
                    <Link href={ponziContract.network.etherscan+"/address/"+ponziContract.network.contractAddr}
                        target="_blank" rel="noreferrer">click here</Link>
                </Typography>
                :
                ''}
            </Box>
            : ''}
             
            <Toolbar edge="start" sx={{ mr: 2, justifyContent:"right", alignContent:"right", alignItems:"right", marginX:0}}>
                <Tooltip title="Refresh">
                <IconButton id="contractStatsRefresh" color="primary" size="large" onClick={()=>{
                    refreshContractStats();                    
                }}>
                    <RefreshOutlinedIcon id="contractStatsRefreshIcon" size="large"/>
                </IconButton>  
                </Tooltip>
                &nbsp;
                <Tooltip title="Help">
                <IconButton id="contractStatsHelp" color="primary" size="large" onClick={()=>{
                    setHelpOpen(true);                    
                    }}>                    
                    <HelpOutlined id="contractStatsHelpIcon" size="large" />
                </IconButton> 
                </Tooltip>                                      
            </Toolbar>
            <Grid container spacing={4}>
            <Grid item xs={6}>
            <ThemeProvider theme={contractBalanceTheme}>
                <Tooltip title="The total balance in the contract. Each token is worth Contract Balance / Total Token Supply.">
                <Paper elevation={3} className='contractBalance' >                          
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>            
                        CONTRACT BALANCE
                    </Typography>                    
                    <Typography variant="body1" component="p" sx={{color: 'primary.main'}} marginLeft={0.5} marginRight={0.5}>
                        {ponziContract.ccy} {PonziContract.formatNumber(contractBalance.ether, 10)}
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*parseFloat(contractBalance.ether)).toFixed(4) : ''}
                    </Typography>     
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        {(contractBalance.time) ? contractBalance.time.toLocaleString(): <CircularProgress size={11}/>}
                    </Typography>                                      
                </Paper> 
                </Tooltip>                           
            </ThemeProvider>  
 
            </Grid>

            <Grid item xs={6}>
            <ThemeProvider theme={contractBalanceTheme}>
                <Tooltip title="The total number of tokens minted so far">
                <Paper elevation={3} className='contractBalance'>              
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>            
                        TOTAL TOKEN SUPPLY
                    </Typography>
                    <Typography variant="body1" component="p" sx={{color: 'primary.main'}} marginLeft={0.5} marginRight={0.5}>
                        {ponziContract.sym} {PonziContract.formatNumber(tokenSupply.ether,10)}
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        &nbsp;
                    </Typography>     
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                        {(tokenSupply.time) ? tokenSupply.time.toLocaleString(): <CircularProgress size={11}/>}
                    </Typography>                   
                </Paper>
                </Tooltip>
            </ThemeProvider>
            </Grid>        
            </Grid>
            </Box>
            <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>    
                {showHelpDialog(helpOpen, openAccordion, setHelpOpen, ponziContract)}
            </Box> 
            <Box sx={{marginY:5}} maxWidth="sm">
            <Grid container spacing={1}>
            <Grid item xs={4}>
            <ThemeProvider theme={bidTheme}>
                <Tooltip title={"The current price at which tokens can be burned. This is the Token Worth - Spread (approx. "+ponziContract.spreadText+")"}>
                <Paper elevation={3} className='bid'>              
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5}>            
                        SELL PRICE
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main', textAlign:'center', justifyContent:'center'}}>
                        {ponziContract.ccy} {PonziContract.formatNumber(sellPrice.ether,8)}                                    
                    </Typography>        
                    
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main', textAlign:'center', justifyContent:'center'}} marginBottom={0.2}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*parseFloat(sellPrice.ether)).toFixed(4) : ''}
                    </Typography>     
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main', textAlign:'center', justifyContent:'center'}} marginBottom={0.2}>
                        {(buyPrice.time) ? buyPrice.time.toLocaleString(): <CircularProgress size={11}/>}
                    </Typography>                
                    
                </Paper>
                </Tooltip>
                </ThemeProvider>
            
            </Grid>

            <Grid item xs={4}>
                <ThemeProvider theme={contractBalanceTheme}>
                <Tooltip title="Worth = Contract Balance/Total Token Supply">
                <Paper elevation={3} className='contractBalance'>
                
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5}>            
                        {ponziContract.sym} WORTH
                    </Typography>            
                    
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}}>
                        {ponziContract.ccy} {PonziContract.formatNumber(tokenWorth.ether, 8)}                    
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        <Stack>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*tokenWorth.ether).toFixed(4) : ''}                    
                        </Stack>
                    </Typography>  
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        {(tokenWorth.time) ? tokenWorth.time.toLocaleString(): <CircularProgress size={11}/>}
                    </Typography>                          

                </Paper>
                </Tooltip>
                </ThemeProvider>
            </Grid>


            <Grid item xs={4}>
                <ThemeProvider theme={offerTheme}>
                <Tooltip title={"The current price at which tokens can be minted. This is the Token Worth + Spread (approx "+ponziContract.spreadText+")"}>
                <Paper elevation={3} className='offer'>
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5}>            
                        BUY PRICE
                    </Typography>            
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}}>
                        {ponziContract.ccy} {PonziContract.formatNumber(buyPrice.ether, 8)}                    
                    </Typography>
                    <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? (ethConvRates.rates[fiatCcy]*buyPrice.ether).toFixed(4) : ''}
                    </Typography>      
                    <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.2}>
                        {(sellPrice.time) ? sellPrice.time.toLocaleString(): <CircularProgress size={11}/>}
                    </Typography>                          
                </Paper>
                </Tooltip>
                </ThemeProvider>
            </Grid>
            </Grid>
            </Box>


            </AccordionDetails>
        </Accordion>

        
        <Transactions ponziContract={ponziContract} setPonziContract={setPonziContract} openAccordion={openAccordion} 
            setOpenAccordion={setOpenAccordion} helpOpen={helpOpen} setHelpOpen={setHelpOpen} transactions={txnsDeserialized} 
            setTransactions={setTransactions} activeUser={activeUser} />


      
       </Container>
    )

}

function Transactions({ponziContract, setPonziContract, openAccordion, setOpenAccordion, helpOpen, setHelpOpen, transactions, setTransactions, activeUser}) {    
    if (!ponziContract || !ponziContract.provider) {
        return;
    }  
    let whoseTransactions = 'My';
    if (ponziContract.simulator && activeUser==='others') {
        whoseTransactions='Others';
    }
    return (
        <Accordion id="myTransactions" sx={{marginY:2}} expanded={openAccordion==='myTransactions'} onChange={(event, expanded)=>{                        
            setOpenAccordion((openAccordion==='myTransactions') ? '' : 'myTransactions');            
        }}>
        <AccordionSummary id="myTransactionsSummary" expandIcon={<ExpandMore/>}>
            <Box sx={{marginY:0, flexDirection:"row"}} maxWidth="sm" display="flex">
            <Typography variant="h6" component="h6" marginTop={0}>
            {whoseTransactions} Transactions&nbsp;
            </Typography>

        </Box>
        </AccordionSummary>               
        <AccordionDetails>           
        {(!ponziContract.simulator) ? 
            <Box sx={{marginY:1}} maxWidth="sm">
                <WalletAlert ponziContract={ponziContract} setPonziContract={setPonziContract} showButton={true}/>
                {(ponziContract.hasSigner) ? 
                <Typography variant="p" component="p">
                    This table only shows transactions stored in your browser's cache. To see all your transactions with this contract&nbsp;
                    <Link href={ponziContract.network.etherscan+"/address/"+ponziContract.signerAddr+
                                "?toaddress="+ponziContract.network.contractAddr}
                        target="_blank" rel="noreferrer">click here</Link>
                </Typography>
                :
                ''}
            </Box>
            : ''}
        <Toolbar edge="start" sx={{ mr: 2, justifyContent:"right", alignContent:"right", alignItems:"right", marginX:0}}>
        <Button variant="outlined" sx={{borderRadius:8, marginLeft:2}} onClick={()=>{
                let st = serializeState(initTxnState);
                //localStorage.setItem('txns2', st);                
                setTransactions(st);
            }}>Clear</Button> 
        </Toolbar>                     
        <Box sx={{marginY:1}} maxWidth="sm">
            <TransactionTable ponziContract={ponziContract} transactions={transactions} activeUser={activeUser} />
        </Box>
        </AccordionDetails>
    </Accordion>
    )
}

function getShortDisplAddress(addr) {
    if (!addr || addr.length<10) return addr;
    return addr.slice(0, 6)+"..."+addr.slice(addr.length-3, addr.length);
}


function TransactionDialog({txn, ponziContract, txnOpen, setTxnOpen}) {
    if (!txn) return;    
    let txnSender=(txn.sender==='user') ? 'Me' : ((txn.sender==='others') ? 'Others' : txn.sender)
    return (
        <Dialog open={txnOpen} 

            onClose={()=>setTxnOpen(false)}
            aria-labelledby="txn-dialog-title"
            aria-describedby="txn-dialog-description"
            sx={{padding:0, marginX:0}}>
            
            <DialogTitle id="txn-dialog-title">
                Transaction {(txn.hash) ? <Link href={ponziContract.network.etherscan+'/tx/'+txn.hash} target="_blank" rel="noreferrer">[View]</Link> : ''}
            </DialogTitle>
            <DialogContent sx={{padding:0, marginX:0}}>                
                <Table aria-label="transaction-table" sx={{padding:0, marginX:0}}>
                    <TableHead>
                        <TableRow>
                        <TableCell style={{width:"20%"}}>

                        </TableCell>
                        <TableCell style={{width:"80%"}}>
                            
                        </TableCell>   
                        </TableRow>                     
                    </TableHead>
                    <TableBody>
                    <TableRow>
                        <TableCell>Sent Time</TableCell>
                        <TableCell>{txn.sentTime.toLocaleString()}</TableCell>
                    </TableRow>
                    <Tooltip title={txn.sender}>
                    <TableRow>
                        <TableCell>Sender</TableCell>
                        <TableCell>
                            {getShortDisplAddress(txnSender)}
                            <IconButton sx={{marginLeft:1}} onClick={()=>navigator.clipboard.writeText(txn.sender)}><ContentCopyOutlined color="primary"/></IconButton>
                        </TableCell>
                    </TableRow>   
                    </Tooltip> 
                    {(txn.side==='transfer') ? 
                        <Tooltip title={txn.receiver}>
                        <TableRow>
                            <TableCell>Receiver</TableCell>
                            <TableCell>
                                {getShortDisplAddress(txn.receiver)}
                                <IconButton sx={{marginLeft:1}}  onClick={()=>navigator.clipboard.writeText(txn.receiver)}><ContentCopyOutlined color="primary"/></IconButton>
                            </TableCell>
                        </TableRow>                            
                        </Tooltip>
                    : ''}                

                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>{(txn.side==='buy')? 'Buy' : ((txn.side==='transfer') ? 'Transfer' : 'Sell')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sent {(txn.side==='buy') ? 'Value' : 'Tokens'}</TableCell>
                        <TableCell>{(txn.side==='buy') ? showWeiValue(txn.sentVal, 8) : showWeiValue(txn.sentTokens, 8)}</TableCell>
                    </TableRow>
                    {(!ponziContract.simulator) ? 
                        (
                        <>
                        <TableRow>
                            <TableCell>Sent Price</TableCell>
                            <TableCell>{showWeiValue(txn.sentPrice, 8)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Slippage%</TableCell>
                            <TableCell>{(txn.slippageBps) ? (parseFloat(txn.slippageBps)/100).toFixed(2) : ''}</TableCell>
                        </TableRow>
                        </>
                        ) : ''}                    
                    <TableRow>
                        <TableCell>Received Price</TableCell>
                        <TableCell>{showWeiValue(txn.recdPrice, 8)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Received {(txn.side==='buy' || txn.side==='transfer') ? 'Tokens' : 'Value'}</TableCell>
                        <TableCell>{(txn.side==='buy' || txn.side==='transfer') ? showWeiValue(txn.recdTokens, 8) : showWeiValue(txn.recdVal, 8)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Received Time</TableCell>
                        <TableCell>{(txn.recdTime) ? txn.recdTime.toLocaleString() : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Fiat Value</TableCell>
                        <TableCell>{txn.fiatCcy} {(txn.fiatVal) ? txn.fiatVal.toFixed(4) : ''}</TableCell>
                    </TableRow>
                    {(txn.error) ? 
                        <TableRow>
                        <TableCell>Error</TableCell>
                        <TableCell>{txn.error}</TableCell>
                    </TableRow>  
                    : ''                  
                    }
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>

    )
}

function TransactionTable({ponziContract, transactions, activeUser}) {
    const [page, setPage] = React.useState(0);    
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [txnRow, setTxnRow] = React.useState(null);
    const [txnOpen, setTxnOpen] = React.useState(false);

    if (!ponziContract) return;
    if (!transactions) return;
    if (!transactions.txns) return;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    let txns;
    if (ponziContract.simulator) {
        txns = transactions.txns.filter((txn)=>txn.sender===activeUser);        
    }
    else {
        txns = transactions.txns;
    }
    txns =txns.slice(page*rowsPerPage, (page*rowsPerPage)+rowsPerPage);
    const openTransactionDialog = (tr)=>{
        setTxnRow(tr);
        setTxnOpen(true);
    }
    return (      
    
    <Paper sx={{ width: '100%', mb: 2 }}>        
    <TransactionDialog ponziContract={ponziContract} txn={txnRow} txnOpen={txnOpen} setTxnOpen={setTxnOpen}/>
    <TableContainer component={Paper}>
    <Table aria-label="transactions-table" stickyHeader>
        <TableHead>                                    
            <TableRow>
                {(ponziContract.simulator) ? <TableCell style={{ width: "5%"}}>Who</TableCell> : ''}
                <TableCell style={{ width: "15%" }}>Sent Time</TableCell>
                <TableCell style={{ width: "10%" }}>Type</TableCell>
                <TableCell style={{ width: "20%" }} align="right" >{ponziContract.sym} Tokens</TableCell>
                <TableCell style={{ width: "20%" }} align="right" >{ponziContract.ccy} Price</TableCell>                
                <TableCell style={{ width: "10%" }}>Info</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>                        
            {txns.map((row)=>{
                if (!row) return (<></>);                
                return (                
                <TableRow key={row.id}>
                    {(ponziContract.simulator) ? ((row.sender==='user') ? 
                        <TableCell><PersonRounded/></TableCell> : <TableCell><PeopleAltRounded/></TableCell>) : ''}
                    <TableCell>{row.sentTime.toLocaleString()}</TableCell>
                    <TableCell>{(row.side==='buy')? 'Buy' : ((row.side==='transfer') ? 'Transfer' : 'Sell')}</TableCell>                    
                    <TableCell align="right">{showWeiValue(row.recdTokens, 8)}</TableCell>                    
                    <TableCell align="right">{showWeiValue(row.recdPrice, 8)}</TableCell>                    
                    <TableCell>
                        <IconButton size="small" onClick={()=>openTransactionDialog(row)} color="primary">
                            <InfoOutlined size="small"/>
                        </IconButton>
                    </TableCell>
                </TableRow>
                )
            
            })}
        </TableBody>
        <TableFooter>
            <TableRow>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={5}
                count={txns.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableRow>
        </TableFooter>
    </Table>
    </TableContainer>

    </Paper>
  
    );
}
function showWeiValue(val, len) {
    if (!val) return;
    return PonziContract.formatNumber(utils.formatEther(val), len);    
}

function getPnl(buyAmount, sellAmount, tokensHeld, sellPrice, ponziContract, fiatCcy, ethConvRates) {
    let initPnl = {wei:ethers.BigNumber.from(0), ether:'0.0', fiat:'0', pct:'0'};
    
    let currentValue = (tokensHeld.wei.mul(sellPrice.wei)).div(ponziContract.decimals);
    let totalSaleValue = sellAmount.wei.add(currentValue);
    let absPnlWei = totalSaleValue.sub(buyAmount.wei);
    let absPnlEther = utils.formatEther(absPnlWei);
    let absPnlFiat = '0';
    if (Object.keys(ethConvRates).length>0) {
        absPnlFiat = (ethConvRates.rates[fiatCcy]*parseFloat(absPnlEther)).toFixed(4);
    }
    let pctPnl;
    if (buyAmount.wei.lt(1)) {
        pctPnl='NA';
    }
    else {
        let pctPnlBps = (absPnlWei.mul(10000)).div(buyAmount.wei);
        pctPnl = (parseFloat(pctPnlBps.toString())/100).toFixed(2);
    }
    return {wei:absPnlWei, ether:absPnlEther, fiat:absPnlFiat, pct:pctPnl};
}
function showHelpDialog(helpOpen, openAccordion, setHelpOpen, ponziContract) {
    if (!helpOpen) return;
    
    if (openAccordion==='contractStats') {
        return showContractStatsHelpDialog(helpOpen, setHelpOpen, ponziContract);
    }
    else if (openAccordion==='myStats') {
        return showMyStatsHelpDialog(helpOpen, setHelpOpen, ponziContract);
    }
}
function showContractStatsHelpDialog(helpOpen, setHelpOpen, ponziContract) {
    
    return (
        <Dialog open={helpOpen} 

            onClose={()=>setHelpOpen(false)}
            aria-labelledby="tokenworth-dialog-title"
            aria-describedby="tokenworth-dialog-description">
            
            <DialogTitle id="tokenworth-dialog-title">
                Contract Stats
            </DialogTitle>
            <DialogContent>                
                
                <List>
                    <ListItem>
                        <ListItemText primary={"Contract Balance = Total "+ponziContract.ccy+" balance in the contract"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Total Token Supply = The number of "+ponziContract.sym+" tokens minted so far"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Sell Price = The price at which a token can be burnt (Token Worth - Spread)"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Token Worth = Contract Balance / Total Token Supply"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Buy Price = The price at which a token can be minted (Token Worth + Spread"}/>
                    </ListItem>     
                    <ListItem>
                        <ListItemText primary={"A spread of "+ponziContract.spreadText+" is applied to the token worth to get the buy and sell prices. A small skew favoring buys is applied when the total token supply is below 10,000 tokens"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"All fiat currency (USD, etc.) equivalents are approximate"}/>
                    </ListItem>                                                                                                    
                </List>
                
            </DialogContent>
            
            <DialogActions>
                <Button onClick={()=>setHelpOpen(false)}><HighlightOffOutlinedIcon/></Button>
            </DialogActions>
        </Dialog>         
    )
}

function showMyStatsHelpDialog(helpOpen, setHelpOpen, ponziContract) {
    
    return (
        <Dialog open={helpOpen} 

            onClose={()=>setHelpOpen(false)}
            aria-labelledby="tokenworth-dialog-title"
            aria-describedby="tokenworth-dialog-description">
            
            <DialogTitle id="tokenworth-dialog-title">
                Contract Stats
            </DialogTitle>
            <DialogContent>                                
                <List>
                    <ListItem>
                        <ListItemText primary={"My Wallet = Total "+ponziContract.ccy+" balance in your wallet"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"My Tokens = The number of "+ponziContract.sym+" tokens currently owned by you. The value of your tokens here is based on the contract balance not the sell price"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Cost Basis = The total "+ponziContract.ccy+" cost of all "+ponziContract.sym+" tokens ever minted by you"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Realized Sale = The all time "+ponziContract.ccy+" value realized so far by burning your "+ponziContract.sym+" tokens"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Unrealized Sale = The value of your "+ponziContract.sym+" tokens at the current sell price"}/>
                    </ListItem>     
                    <ListItem>
                        <ListItemText primary={"Current Profit/Loss = The overall profit/loss on your current and past "+ponziContract.sym+" holdings = ((Realized Sale + Unrealized Sale)-Cost Basis) / Cost Basis"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"All fiat currency (USD, etc.) equivalents are approximate"}/>
                    </ListItem>                                                                                                    
                </List>                
            </DialogContent>
            
            <DialogActions>
                <Button onClick={()=>setHelpOpen(false)}><HighlightOffOutlinedIcon/></Button>
            </DialogActions>
        </Dialog>         
    )
}