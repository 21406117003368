const { ethers, utils, BigNumber } = require("ethers");

//bigcoin
export class PonziSimulatorContract {
    constructor(provider) {        
        this.decimals = utils.parseUnits('1');
        this.halfDecimals = BigNumber.from('10').pow(9);
        this.minPrice = this.decimals.div(1000);
        this.MAX_MULT_VAL = BigNumber.from('115792089237316195423570985008687907853269');
        this.spreadBps = BigNumber.from('500');
        this.criticalSupplyLevel = utils.parseUnits('1');

        //this.mintValue = BigNumber.from('0');
        //this.burnValue = BigNumber.from('0');
        
        //Deep close state
        this.state = provider.state; //JSON.parse(JSON.stringify(provider.state));
        //this.contractTokens = BigNumber.from(provider.state.totalSupply);
        //this.contractBalance = BigNumber.from(provider.state.balance);
        
        //this.userBalance=BigNumber.from(provider.state.userBalance);
        //this.userTokens=BigNumber.from(provider.state.userTokens);
        this.setState=provider.setProviderState.bind(provider);
        this.sym="PNZ";
        this.provider=provider;      
        //this.provider.connectedUser="user";  
    }

    //getState() {
    //    return {
    //        totalSupply:this.contractTokens.toString(),
    //        balance:this.contractBalance.toString(),
    //        userBalance:this.userBalance.toString(),
    //        userTokens:this.userTokens.toString()
    //    }
    // }

    async totalSupply() {
        //return this.contractTokens;
        return this.state.contractTokens;
    }

    async DECIMALS() {
        return this.decimals;
    }

    async HALF_DECIMALS() {
        return this.halfDecimals;
    }

    async MIN_PRICE() {
        return this.minPrice;
    }

    async SPREAD_BPS() {
        return this.spreadBps;
    }

    async CRITICAL_SUPPLY_LEVEL() {
        return this.criticalSupplyLevel;
    }

    async balanceOf(addr) {
        return this.state.userTokens[addr];
    }

    async symbol() {
        return this.sym;
    }

    async safeMint(expectedBuyPrice, allowedSlippageBps, props={}) {
        if (!props.value || !props.value.gt(0)) throw new Error("Message must have value");
        let buyPrice = this.getBuyPrice_internal(this.state.contractBalance);
        if (buyPrice.gt(expectedBuyPrice.add(this.getBpsValue(allowedSlippageBps, expectedBuyPrice)))) throw new Error("Slippage limit exceeded on buy price");
        let ret = this.mintInternal(this.provider.connectedUser, buyPrice, props.value);
        this.state.contractBalance=this.state.contractBalance.add(props.value);        
        this.state.userBalance[this.provider.connectedUser]=this.state.userBalance[this.provider.connectedUser].sub(props.value);
        this.setState(this.state);
        return ret;        
    }


    async mint(props={}) {
        if (!props.value || !props.value.gt(0)) throw new Error("Message must have value");        

        let buyPrice = this.getBuyPrice_internal(this.state.contractBalance.sub(props.value));        
        let ret =  this.mintInternal(this.provider.connectedUser, buyPrice, props.value);
        this.state.contractBalance=this.state.contractBalance.add(props.value);
        this.state.userBalance[this.provider.connectedUser]=this.state.userBalance[this.provider.connectedUser].sub(props.value);
        this.setState(this.state);
        return ret;
    }
    
    mintInternal(sender, buyPrice, buyValue) {        
        let numTokens = (buyValue.mul(this.decimals)).div(buyPrice);
        if (!numTokens.gt(0)) throw new Error("Send amount too low to buy tokens");        
        this.state.mintValue[this.provider.connectedUser] = this.state.mintValue[this.provider.connectedUser].add(buyValue);
        this.state.contractTokens = this.state.contractTokens.add(numTokens);
        this.state.userTokens[this.provider.connectedUser] = this.state.userTokens[this.provider.connectedUser].add(numTokens);  
        this.state.transactions.push({ts:new Date(), sn:sender, sd:'buy', px:buyPrice, vl:buyValue, tk:numTokens, 
            cb:this.state.contractBalance.add(buyValue), ct:this.state.contractTokens});        
        return {
                wait:async ()=>{return {events:{find:(fn)=>{return {args:[sender, numTokens, buyPrice, buyValue]}}}}}
            };        
    }

    async safeBurn(expectedSellPrice, allowedSlippageBps, numTokens) {
        if (this.state.userTokens[this.provider.connectedUser].lt(numTokens)) throw new Error("Sender has insufficient tokens");
        if (numTokens.gt(this.state.contractTokens)) throw new Error("Insufficient tokens in supply");
        if (!this.state.contractBalance.gt(0)) throw new Error("No balance in contract currently");
        if (this.state.contractTokens.lt(this.criticalSupplyLevel)) throw new Error("Token supply below critical level - cannot sell");
        if (this.state.contractTokens.lt(this.criticalSupplyLevel.add(numTokens))) throw new Error("Burn would bring supply below critical level - retry will smaller token count");

        let sellPrice = await this.getSellPrice();

        if (sellPrice.lt(expectedSellPrice.sub(this.getBpsValue(allowedSlippageBps, expectedSellPrice)))) throw new Error("Slippage limit exceeded on expected sell price");
        let sellValue = this.burnInternal(this.provider.connectedUser, sellPrice, numTokens);
        this.state.contractBalance=this.state.contractBalance.sub(sellValue);
        this.state.userBalance[this.provider.connectedUser]=this.state.userBalance[this.provider.connectedUser].add(sellValue);
        this.setState(this.state);
        return {
            wait:async ()=>{return {events:{find:(fn)=>{return {args:[this.provider.userAddr, numTokens, sellPrice, sellValue]}}}}}
        };          
    }

    async burn(numTokens) {        
        if (this.state.userTokens[this.provider.connectedUser].lt(numTokens)) throw new Error("Sender has insufficient tokens");
        if (numTokens.gt(this.state.contractTokens)) throw new Error("Insufficient tokens in supply");
        if (!this.state.contractBalance.gt(0)) throw new Error("No balance in contract currently");
        if (this.state.contractTokens.lt(this.criticalSupplyLevel)) throw new Error("Token supply below critical level - cannot sell");
        if (this.state.contractTokens.lt(this.criticalSupplyLevel.add(numTokens))) throw new Error("Burn would bring supply below critical level - retry will smaller token count");

        let sellPrice = await this.getSellPrice();
        let sellValue = this.burnInternal(this.provider.connectedUser, sellPrice, numTokens);
        this.state.contractBalance=this.state.contractBalance.sub(sellValue);
        this.state.userBalance[this.provider.connectedUser]=this.state.userBalance[this.provider.connectedUser].add(sellValue);
        this.setState(this.state);
        return {
            wait:async ()=>{return {events:{find:(fn)=>{return {args:[this.provider.userAddr, numTokens, sellPrice, sellValue]}}}}}
        };           
    }

    burnInternal(sender, sellPrice, numTokens) {
        
        let sellValue;
        if (((sellPrice.div(this.decimals)).mul(numTokens.div(this.decimals))).gt(this.MAX_MULT_VAL)) {
            sellValue = (sellPrice.div(this.halfDecimals)).mul(numTokens.div(this.halfDecimals));            
        }
        else {
            sellValue = (sellPrice.mul(numTokens)).div(this.decimals);
        }
        this.state.burnValue[this.provider.connectedUser]=this.state.burnValue[this.provider.connectedUser].add(sellValue);
        this.state.contractTokens=this.state.contractTokens.sub(numTokens);
        this.state.userTokens[this.provider.connectedUser]=this.state.userTokens[this.provider.connectedUser].sub(numTokens);   
        this.state.transactions.push({ts:new Date(), sn:sender, sd:'sell', px:sellPrice, vl:sellValue, tk:numTokens,
                    cb:this.state.contractBalance.sub(sellValue), ct:this.state.contractTokens});     
        return sellValue;         
    }

    getBpsValue(bps, val) {
        return (val.mul(bps)).div(10000);
    }

    getSkewBps(ts) {
        let skewBps = BigNumber.from('0');
        if (ts.lt(utils.parseUnits('10'))) skewBps = BigNumber.from('450');
        else if (ts.lt(utils.parseUnits('100'))) skewBps = BigNumber.from('400');
        else if (ts.lt(utils.parseUnits('2500'))) skewBps = BigNumber.from('300');
        else if (ts.lt(utils.parseUnits('5000'))) skewBps = BigNumber.from('200');
        else if (ts.lt(utils.parseUnits('10000'))) skewBps = BigNumber.from('100');
        return skewBps;
    }

    getBuyPrice_internal(bal) {
        if (this.state.contractTokens.lt(utils.parseUnits('1'))) return this.minPrice;
        let price = (bal.mul(this.decimals)).div(this.state.contractTokens);
        
        if (price.lt(this.minPrice)) price = this.minPrice;
        let spreadBps = this.spreadBps.sub(this.getSkewBps(this.state.contractTokens));
        price = price.add(this.getBpsValue(spreadBps, price));
        
        return price;
    }

    async getBuyPrice() {
        return this.getBuyPrice_internal(this.state.contractBalance);
    }

    getSellPrice_internal(bal) {             
        if (bal.eq(0) || this.state.contractTokens.lt(utils.parseUnits('1'))) return 0;
        let price = (bal.mul(this.decimals)).div(this.state.contractTokens);        
        let spreadBps = this.spreadBps.add(this.getSkewBps(this.state.contractTokens));        
        price = price.sub(this.getBpsValue(spreadBps, price));
        return price;
    }

    async getSellPrice() {
        return this.getSellPrice_internal(this.state.contractBalance);
    }

    async getContractBalance() {
        return this.state.contractBalance;
    }

    async getMintValue(address) {
        
        return this.state.mintValue[address];
    }

    async getBurnValue(address) {
        return this.state.burnValue[address];
    }

    connect(signer) {
        signer.getAddress().then((addr)=>{this.provider.connectedUser=addr});
        return this;
    }
}
