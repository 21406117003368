import { HighlightOffOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { PonziContract } from "../utils/ponziContract2";

export default function WalletDialog({walletDialogOpen, setWalletDialogOpen, ponziContract, setPonziContract}) {
    if (!walletDialogOpen) return;
    
    return (                        
        <Dialog open={walletDialogOpen} 

        onClose={()=>setWalletDialogOpen(false)}
        aria-labelledby="wallet-dialog-title"
        aria-describedby="wallet-dialog-description">
        
        <DialogTitle id="wallet-dialog-title">
            Connect
        </DialogTitle>
        <DialogContent>                
            <DialogContentText id="wallet-dialog-description">
            Connect with your wallet of choice to trade {ponziContract.sym}
            <br/>
            <Stack sx={{marginY:2}}>
            <Button variant="outlined" target="_blank" href="dapp://ponzicoin.app/" 
                sx={{ borderRadius:8 }}
                style={{justifyContent: "flex-start"}}>
                <img width="28" height="28" src="metamask.png" alt="Metamask Logo"/>&nbsp;Metamask
            </Button>
            <br/>
            <Button variant="outlined" target="_blank" href="https://link.trustwallet.com/open_url?coin_id=714&url=https://ponzicoin.app/" 
                sx={{ borderRadius:8 }}
                style={{justifyContent: "flex-start"}}>
                <img width="28" height="28" src="trustwallet.svg" alt="Trust Wallet Logo"/>&nbsp;Trust Wallet
            </Button>                
            <br/>
            <Button variant="outlined" target="_blank" onClick={()=>{                    
                        const wcProvider = new WalletConnectProvider({
                        infuraId: PonziContract.infuraId
                        });
                        wcProvider.enable().then(()=>{
                        let prv = new ethers.providers.Web3Provider(wcProvider);    
                        setPonziContract(new PonziContract(prv));                    
                        });
                    }}
                sx={{ borderRadius:8 }}
                style={{justifyContent: "flex-start"}}>
                <img width="28" height="28" src="walletconnect.png" alt="Wallet Connect Logo"/>&nbsp;WalletConnect
            </Button>                
            <br/>                
            <Button variant="outlined" target="_blank" href="mathwallet://mathwallet.org?action=link&value=https://ponzicoin.app/"
                sx={{ borderRadius:8 }}
                style={{justifyContent: "flex-start"}}>
                
                <img width="28" height="28" src="mathwallet.png" alt="Math Wallet Logo"/>&nbsp;Math Wallet
            </Button>
            
            </Stack>
            </DialogContentText>
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>setWalletDialogOpen(false)}><HighlightOffOutlined/></Button>
        </DialogActions>
    </Dialog>                
    );
    
}
