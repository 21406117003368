import { BigNumber } from "ethers";
import LZString from "lz-string";
import React, { useState } from "react";

// Hook
export function useLocalStorage(key, initialValue) {        
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
      if (typeof window === "undefined") {
        return initialValue;
      }
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    };
    return [storedValue, setValue];
  }

export const useSemiPersistentState = (key, initialState) => {
    const [value, setValue] = React.useState(localStorage.getItem(key) || initialState);

    React.useEffect(() => {
        localStorage.setItem(key, value);
    }, [value, key]);

    return [value, setValue];
    };

export const parseJSONBignumbers = (json)=>{
    let k;
    
    for (k in json) {
        if (json.hasOwnProperty(k)) {
            if (json[k].type && json[k].hex && json[k].type==='BigNumber') {
                json[k] = BigNumber.from(json[k].hex);
            }
            else if (json[k]!==null && (typeof json[k])==='object' && !Array.isArray(json[k])) {
                parseJSONBignumbers(json[k]);
            }
            else if (Array.isArray(json[k])) {
                json[k] = json[k].map((j)=>{                    
                    parseJSONBignumbers(j);                    
                    return j;
                });
            }
            else if (k.indexOf('Time')>0) {
                json[k] = new Date(json[k]);
            }
        }
    }    
}

export const serializeState = (state) => LZString.compress(JSON.stringify(state));
    

export const deserializeState = (state) => {
    let ret = JSON.parse((LZString.decompress(state)));
    parseJSONBignumbers(ret);
    return ret;
}

export const cleanDecimal = (str) =>     
    str.trim().replace(/[^\d.]/g,'').replace('-', '');

export const cleanInteger = (str) =>     
    str.trim().replace(/[^\d]/g,'').replace('-', '');    