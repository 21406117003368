
import {  serializeState } from "./utils";

const {  utils } = require("ethers");



//bigcoin
export class PonziSimulatorProvider {
    static contractAddr=utils.getAddress("0x777f67135E88e32Efcdbf4aA93231939332Aa6F3");
    constructor(state, setState) {        
        //let tempState=deserializeState(state);
        //console.log("Before State = ");
        //console.log(tempState);
        //parseJSONBignumbers(tempState);        
        //this.state=tempState;
        this.state=state;
        this.setState=setState;
        this.ready = new Promise((resolve, reject)=>resolve());
        this.contractAddr=PonziSimulatorProvider.contractAddr;
        this.userAddr="user"; //utils.getAddress("0x209B3DDfFccDFD23727055923528C13Cf5776c83");
        this.othersAddr="others";
        this.connectedUser = "user";        
    }

    setProviderState(state) {
        this.state = state;                
        this.setState(serializeState(state));
    }

    async getNetwork() {
        return {
            name:'simulator'
        }
    };

    setConnectedUser(user) {
        this.connectedUser = user;        
    }

    getOthersSigner() {
        return {
            getAddress:async () => this.othersAddr
        }
    }

    getSigner() {        
        return {
            getAddress:async () => this.connectedUser
        }
    }

    async getBalance(addr) { 
        if (addr===this.contractAddr) {
            return this.state.contractBalance
        }       
        else {
            return this.state.userBalance[addr];
        }
    }


}
