import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { useState } from "react";
import Container from "@mui/material/Container";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Alert, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, Slide, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { ethers } from 'ethers';
import { PonziContract } from '../utils/ponziContract2';
import { parseUnits } from 'ethers/lib/utils';
import RefreshOutlinedIcon  from '@mui/icons-material/RefreshOutlined';
import HighlightOffOutlinedIcon  from '@mui/icons-material/HighlightOffOutlined';
import { CircularProgress } from '@mui/material';
import { Help } from '@mui/icons-material';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { deserializeState, serializeState } from "../utils/utils";
import WalletDialog from '../components/WalletDialog';

const bidTheme = createTheme({
    palette: {
      background:"#b22222",
      primary: {
        main: "#f5f5dc",
        light: "#f5f5dc",
        dark: "#f5f5dc"        
      }
    }
    
  });
  
  const offerTheme = createTheme({
    palette: {
      background:"#008000",
      primary: {
        main: "#f5f5dc",
        light: "#f5f5dc",
        dark: "#f5f5dc"
      }
    }
  
  });

const bigZero=ethers.BigNumber.from(0);
const initPrice = {wei:bigZero, ether:'0.0'};

const PriceDisplay = ({fiatCcy, buyPrice, sellPrice, ethRates, refreshPrices, ponziContract}) =>{
    
    return (
    <Box sx={{marginY:5}} maxWidth="sm">
    <Grid container spacing={5}>
    <Grid item xs={6}>
    <ThemeProvider theme={bidTheme}>
        <Paper elevation={3} className='bid'>              
            <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>            
                SELL
            </Typography>
            <Typography variant="h5" component="h6" sx={{color: 'primary.main'}} marginLeft={0.5} marginRight={0.5}>
                {ponziContract.ccy} {PonziContract.formatNumber(sellPrice.ether, 8)}

                <IconButton color="inherit" size="small" onClick={refreshPrices}><RefreshOutlinedIcon size="small"/></IconButton>   
            </Typography>        
            
            <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                {fiatCcy} {(ethRates && Object.keys(ethRates).length>0) ? (ethRates.rates[fiatCcy]*parseFloat(sellPrice.ether)).toFixed(4) : ''}
            </Typography>     
            <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
                {(sellPrice.time) ? sellPrice.time.toLocaleString(): <CircularProgress size={11}/>}
            </Typography>                
             
        </Paper>
        </ThemeProvider>
    
    </Grid>
    
    <Grid item xs={6}>
        <ThemeProvider theme={offerTheme}>
        <Paper elevation={3} className='offer'>
        <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>            
            BUY
        </Typography>            
        <Typography variant="h5" component="h6" sx={{color: 'primary.main'}}>
            {ponziContract.ccy} {PonziContract.formatNumber(buyPrice.ether, 8)}
            <IconButton color="inherit" size="small" onClick={refreshPrices}><RefreshOutlinedIcon size="small"/></IconButton>   
        </Typography>
        <Typography variant="body2" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
            {fiatCcy} {(ethRates && Object.keys(ethRates).length>0) ? (ethRates.rates[fiatCcy]*parseFloat(buyPrice.ether)).toFixed(4) : ''}
        </Typography>      
        <Typography variant="body3" component="h6" sx={{color: 'primary.main'}} marginBottom={0.5} marginLeft={0.0} marginRight={0.5}>
            {(buyPrice.time) ? buyPrice.time.toLocaleString(): <CircularProgress size={11}/>}
        </Typography>                          
        </Paper>
        </ThemeProvider>
    </Grid>
    </Grid>
    </Box>
    );
}

const useSemiPersistentState = (key, initialState) => {
    const [value, setValue] = React.useState(localStorage.getItem(key) || initialState);
  
    React.useEffect(() => {
      localStorage.setItem(key, value);
    }, [value, key]);
  
    return [value, setValue];
  };
const initValue = {wei:bigZero, ether:'0'};
const TradeAction = ({buyPrice, sellPrice, fiatCcy, ethRates, ponziContract, setPonziContract, isIdle, setBuyPrice, setSellPrice, transactions, setTransactions}) => {
    //const initTxnState = {txns:[]};
    const [ethBalance, setEthBalance]=useState(initValue);
    const [tokenBalance, setTokenBalance]=useState(initValue);
    const [side, setSide] = useState('buy');
    const [amount, setAmount] = useState(initValue);
    const [tokens, setTokens] = useState(initValue);    
    const [message, setMessage]=useState('');
    const [slippagePct, setSlippagePct]=useSemiPersistentState('slippagePct', '0.20');
    const [lastChanged, setLastChanged] = useState('');
    const [helpOpen, setHelpOpen] = useState(false);
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);
    //const [transactions, setTransactions] = useSemiPersistentState('txns2', serializeState(initTxnState));
    const amountInputRef = React.useRef();
    const tokensInputRef = React.useRef();

    
    React.useEffect(()=>{    
        if (ponziContract && ponziContract.provider && !isIdle) {

            ponziContract.getSignerEtherBalance(setEthBalance);
            setTimeout(()=>ponziContract.getSignerEtherBalance(setEthBalance), 1000);
            setTimeout(()=>ponziContract.getSignerEtherBalance(setEthBalance), 3000);
            setTimeout(()=>ponziContract.getSignerEtherBalance(setEthBalance), 5000);
            ponziContract.getSignerTokenBalance(setTokenBalance, setMessage);
            const interval1=setInterval(()=>{ponziContract.getSignerEtherBalance(setEthBalance);}, 60000);
            const interval2=setInterval(()=>{ponziContract.getSignerTokenBalance(setTokenBalance, setMessage);}, 60000);

            if (ponziContract.network) {
                let msg="Connected to network "+ponziContract.network.network.name;
                if (ponziContract.network.network.name==='simulator') {
                    msg="Warning: Connected to simulator. Nothing is real."
                }
                if (!ponziContract.hasSigner) {
                    msg = msg+". Wallet not connected."
                }
                setMessage(msg);
            }
            return () => {
                clearInterval(interval1);
                clearInterval(interval2);
            }
        }

    }, [ponziContract, isIdle]);    

    const handleSlippagePctChange = (event) => {        
        
        let strPct;
        
        if (event.target.value) {
            strPct=event.target.value;
        }
        else if (event.target.innerText) {
            strPct=event.target.innerText;
        }

        if (!strPct) strPct='';
        console.log(strPct);        
        strPct=cleanNumeric(strPct);
        console.log(strPct);

        if (strPct==='') {            
            setSlippagePct('0');   
            return;         
        }
        else {
            setSlippagePct(strPct);
        }           
    }

    const slippagePctOptions=['0.10', '0.20', '0.50', '1.00', '2.00', '5.00', '10.00'];

    const handleAmountChangeEvent = (event) => {
        let strAmt = event.target.value;
        handleAmountChange(strAmt, side);
        setLastChanged('amount');
    }

    const handleAmountChange = (strAmt, a_side) => {        
        strAmt = cleanNumeric(strAmt);
        if (strAmt==='') {
            setTokens(initValue);            
        }
        let amt=parseFloat(strAmt);
        if (Number.isNaN(amt) || strAmt.endsWith(".")) {
            setAmount({
                wei:bigZero,
                ether:strAmt
            });
            if (amt===0) setTokens(initValue);
        }
        else {            
            setAmount({
                wei:parseUnits(amt.toString()),
                ether:strAmt
            });
            
            ponziContract.getTokensFromValue(a_side, buyPrice, sellPrice, amt.toString(), setTokens);
        }
    }
    
    const handleTokensChangeEvent = (event) => {
        let strTokens = event.target.value;        
        handleTokensChange(strTokens, side);
        setLastChanged('tokens');
    }

    const handleTokensChange = (strTokens, a_side) => {
        strTokens=cleanNumeric(strTokens);
        if (strTokens==='') {            
            setAmount(initValue);            
        }
        let tkns = parseFloat(strTokens);      
        if (Number.isNaN(tkns) || strTokens.endsWith(".")) {
            setTokens({
                wei:0,
                ether:strTokens
            });
            if (tkns===0) setAmount(initValue);
        }
        else {
            setTokens({
                wei:parseUnits(tkns.toString()),
                ether:strTokens
            });
            ponziContract.getValueFromTokens(a_side, buyPrice, sellPrice, tkns.toString(), setAmount);
        }
    }



    const clearValues = (inputRef) => {
        setAmount(initValue);
        setTokens(initValue);
        if (inputRef) {
            inputRef.current.select();
            inputRef.current.focus();
            
        }
    }    

    const onSideChanged = (newSide) => {
        try {
            if (lastChanged==='amount') {
                handleAmountChange(amount.ether, newSide);
            }
            else if (lastChanged==='tokens') {
                handleTokensChange(tokens.ether, newSide);
            }            
        }
        catch (e) {
            console.log(e);
            clearValues(null);
        }
        setSide(newSide);

    }

    const handleSideChangeEvent = (event) => {
        let newSide=event.target.value;
        onSideChanged(newSide);
    }    

    React.useEffect(()=>{
        if (lastChanged==='amount') {
            handleAmountChange(amount.ether, side);
        }
        else if (lastChanged==='tokens') {
            handleTokensChange(tokens.ether, side);
        }
    }, [buyPrice, sellPrice]);
        
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
      });  

    const addTransaction = (txn) => {
        let txns = deserializeState(transactions);
        txns.txns.unshift(txn);
        console.log("Adding Transaction..");
        console.log(txn);
        setTransactions(serializeState(txns));        
    }

    return (        
    <Box sx={{marginY:1}} maxWidth="sm">
        <Box maxWidth="sm" sx={{marginY:1, textAlign:'center', justifyContent:'center'}}>

        {showAlert(message, setMessage)}
            <ToggleButtonGroup value={side} exclusive onChange={handleSideChangeEvent} sx={{marginX:2}}>
                <ToggleButton value="sell">Sell {ponziContract.sym}</ToggleButton>
                <ToggleButton value="buy" >Buy {ponziContract.sym}</ToggleButton>                
        </ToggleButtonGroup>
        </Box>
        

        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>                             
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="tokens">Tokens</InputLabel>
          <OutlinedInput
            id="tokens"
            value={tokens.ether}
            onChange={handleTokensChangeEvent}
            startAdornment={<InputAdornment position="start">{ponziContract.sym}</InputAdornment>}
            endAdornment= {
                <IconButton onClick={() => clearValues(amountInputRef)}>
                  <HighlightOffOutlinedIcon/>
                </IconButton>
            }            
            label="Amount"
            inputProps={{ inputMode: 'decimal', pattern: '[0-9.]*' }}
            inputRef={amountInputRef}
            onFocus = {event=>event.target.select()}
          />
          <Typography variant="body3" component="h6" sx={{textAlign:'center'}}>
          Balance: {ponziContract.sym} {tokenBalance.ether.substring(0, 10)}
          </Typography>          
        </FormControl>         
        </Box>
        <Typography variant="h6" component="h6" sx={{textAlign:'center'}}>
        =
        </Typography>           
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>                        
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="amount">Amount</InputLabel>
          <OutlinedInput
            id="amount"
            value={amount.ether}
            onChange={handleAmountChangeEvent}            
            startAdornment={<InputAdornment position="start">{ponziContract.ccy}</InputAdornment>}
            endAdornment= {
                <IconButton onClick={() => clearValues(tokensInputRef)}>
                  <HighlightOffOutlinedIcon/>
                </IconButton>
            }
            label="Amount"
            inputProps={{ inputMode: 'decimal', pattern: '[0-9.]*' }}
            inputRef={tokensInputRef}
            onFocus = {event=>event.target.select()}
          />
          <Typography variant="body3" component="h6" sx={{textAlign:'center'}}>
          Balance: {ponziContract.ccy} {ethBalance.ether.substring(0, 10)}
          </Typography>
        </FormControl>              
        <Typography variant="body2" component="h6" sx={{textAlign:'center'}}>            
            {fiatCcy} {(Object.keys(ethRates).length>0) ? (ethRates.rates[fiatCcy]*amount.ether).toFixed(4) : 'NA'}
        </Typography>
        </Box>

        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>    
            {showSlippageHelp(helpOpen, setHelpOpen, Transition)}
            <WalletDialog walletDialogOpen={walletDialogOpen} setWalletDialogOpen={setWalletDialogOpen} ponziContract={ponziContract}
                setPonziContract={setPonziContract}/>            
        </Box>
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>    
        <FormControl size="small">
            <Grid container spacing={2}>
                <Grid item xs={8}>
                <Autocomplete sx={{width:90}}
                    id="slippagePct" freeSolo options={slippagePctOptions} value={slippagePct}
                    renderInput={(params) => <TextField {...params} label="Slippage%" onChange={handleSlippagePctChange} value={slippagePct} />}
                    onChange={handleSlippagePctChange} size="small" selectOnFocus 
                    />
                </Grid>
                <Grid item xs={4}>
                <Help onClick={()=>{setHelpOpen(true)}} sx={{marginY:1}}/>
                </Grid>

            </Grid>

                
            </FormControl>  
        </Box>                      
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>     
             {showActionButton(ponziContract, setWalletDialogOpen, side, amount, tokens, buyPrice, sellPrice, slippagePct, setTokenBalance, 
                    setEthBalance, setMessage, setBuyPrice, setSellPrice, addTransaction, ethRates, fiatCcy)}       
            

        </Box>
         
    </Box>
    );
}

function showActionButton(ponziContract, setWalletDialogOpen, side, amount, tokens, buyPrice, sellPrice, slippagePct, 
            setTokenBalance, setEthBalance, setMessage, setBuyPrice, setSellPrice, addTransaction, ethRates, fiatCcy) {
    if (!ponziContract || !ponziContract.provider || !ponziContract.isConnected || !ponziContract.hasSigner) {
        return (
            <Button variant="contained" size="large" sx={{ borderRadius:8 }}
                onClick={()=>setWalletDialogOpen(true)}>Connect Wallet</Button>
        );
    }
    else {
        return (
            <Button variant="contained" size="large" disabled={(!ponziContract || !ponziContract.isConnected || !ponziContract.hasSigner)}
                sx={{ borderRadius:8 }}
                onClick={() => ponziContract.transactTokens(side, amount, tokens, buyPrice.wei, sellPrice.wei,
                     Math.round(parseFloat(slippagePct)*100), setTokenBalance, setEthBalance, setMessage, setBuyPrice, setSellPrice,
                     ethRates, fiatCcy, addTransaction.bind(this))}>Go</Button>

        )
    }
}

function showSlippageHelp(helpOpen, setHelpOpen, transition) {
    if (!helpOpen) return;
    return (        
        <Dialog open={helpOpen} 

            onClose={()=>setHelpOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            
            <DialogTitle id="alert-dialog-title">
                Slippage%
            </DialogTitle>
            <DialogContent>                
                <DialogContentText id="alert-dialog-description">
                The price you see is updated every minute. In busy periods it may change after you click Go. Use slippage% to specify the maximum downside between 
                the price you see and the price you get
                </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={()=>setHelpOpen(false)}><HighlightOffOutlinedIcon/></Button>
            </DialogActions>
        </Dialog>         
    )
}
function showAlert(message, setMessage) {
    if (message==='') return;
    let color="info";
    let severity="info";
    if (message.includes("Error")) {
        color="error";
        severity="error";
    }
    else if (message.includes("Completed")) {
        color="success";
        severity="success";
    }
    else if (message.includes("Warning")) {
        color="warning";
        severity="warning";
    }
    return (
        <Alert sx={{textAlign:'center', justifyContent:'center', marginY:2}}
            color={color} severity={severity} onClose={()=>setMessage('')}>
                {(message.includes('Pending')) ? (<CircularProgress size={11} sx={{marginX:2}}/>) : ''}
                {message}
        </Alert>
    )    
}


const cleanNumeric = (str) =>     
    str.trim().replace(/[^\d.]/g,'').replace('-', '');

export default function Trade({fiatCcy, ponziContract, setPonziContract, isIdle, refreshCounter, transactions, setTransactions}) {    
    const [buyPrice, setBuyPrice] = useState(initPrice);
    const [sellPrice, setSellPrice] = useState(initPrice);
    const [ethConvRates, setEthConvRates] = useState({});

    const refreshPrices = () => {       
        ponziContract.getEthConvRates(setEthConvRates);     
        ponziContract.getBuyPrice(setBuyPrice);
        ponziContract.getSellPrice(setSellPrice);        
    }       

    React.useEffect(()=>{          
        if (isIdle) return;                
        refreshPrices();
        const interval=setInterval(refreshPrices, 30000);
        return () => clearInterval(interval);          
    }, [ponziContract, isIdle, refreshCounter]);      


    const refreshPriceDisplay = () => {
        ponziContract.getBuyPrice(setBuyPrice);
        ponziContract.getSellPrice(setSellPrice);        
    }   

    return (
    <Container maxWidth="sm">
        <Box maxWidth="sm" sx={{marginY:3, textAlign:'center', justifyContent:'center'}}>

        </Box>   
        
        <PriceDisplay fiatCcy={fiatCcy} ethRates={ethConvRates} buyPrice={buyPrice} sellPrice={sellPrice} 
            setBuyPrice={setBuyPrice} setSellPrice={setSellPrice} ponziContract={ponziContract} refreshPrices={refreshPriceDisplay} 
            isIdle={isIdle} />
        <TradeAction             
            fiatCcy={fiatCcy} ethRates={ethConvRates} ponziContract={ponziContract} buyPrice={buyPrice} sellPrice={sellPrice}
            setPonziContract={setPonziContract} isIdle={isIdle} setBuyPrice={setBuyPrice} setSellPrice={setSellPrice} 
            transactions={transactions} setTransactions={setTransactions}
            />
    
    </Container>    
    )
}