import { HelpOutlined, HighlightOffOutlined } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Toolbar, Tooltip, Typography } from "@mui/material";
import { ethers, utils } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import React, { useState } from "react";
import WalletDialog from "../components/WalletDialog";
import { PonziContract } from "../utils/ponziContract2";
import { cleanDecimal, deserializeState, serializeState } from "../utils/utils";



const bigZero=ethers.BigNumber.from(0);
const initPrice = {wei:bigZero, ether:'0.0'};

function showAlert(message, setMessage) {
  if (message==='') return;
  let color="info";
  let severity="info";
  if (message.includes("Error")) {
      color="error";
      severity="error";
  }
  else if (message.includes("Completed")) {
      color="success";
      severity="success";
  }
  else if (message.includes("Warning")) {
      color="warning";
      severity="warning";
  }
  return (
      <Alert sx={{textAlign:'center', justifyContent:'center', marginY:2}}
          color={color} severity={severity} onClose={()=>setMessage('')}>
              {(message.includes('Pending')) ? (<CircularProgress size={11} sx={{marginX:2}}/>) : ''}
              {message}
      </Alert>
  )    
}

export default function Transfer({ponziContract, setPonziContract, fiatCcy, isIdle, transactions, setTransactions}) {  
    const [ethConvRates, setEthConvRates] = useState({});     
    const [tokenWorth, setTokenWorth] = useState(initPrice);
    const [helpOpen, setHelpOpen] = useState(false);    
    const [myTokens, setMyTokens] = useState(initPrice);   
    const [contractBalance, setContractBalance] = useState(initPrice);
    const [tokenSupply, setTokenSupply] = useState(initPrice);
    const [tokens, setTokens] = useState(initPrice);    
    const [message, setMessage]=useState('');
    const [toAddress, setToAddress] = useState('');
    const [toTokenBalance, setToTokenBalance] = useState(initPrice);
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);
    
    React.useEffect(()=>{        
        const refresh = () => {
          ponziContract.getEthConvRates(setEthConvRates);
          ponziContract.getSignerTokenBalance(setMyTokens);
          ponziContract.getContractBalance(setContractBalance);
          ponziContract.getTokenSupply(setTokenSupply);          
        };
        refresh();
        const interval = setInterval(()=>{
            if (ponziContract) {                    
                refresh();
            }}, 60000);
        return ()=>clearInterval(interval);
      }, [ponziContract]);    

      React.useEffect(()=>{        
        if (contractBalance.wei>0 && tokenSupply.wei>0){
            let tokenWorthWei=(contractBalance.wei.mul(ponziContract.decimals)).div(tokenSupply.wei);            
            setTokenWorth({
                wei:tokenWorthWei,
                ether:utils.formatEther(tokenWorthWei),
                time:new Date()
            });
        }
    }, [contractBalance, tokenSupply]);
      

    const handleTokensChangeEvent = (event) => {
      let strTokens = event.target.value;        
      handleTokensChange(strTokens);
    }

    const handleTokensChange = (strTokens) => {
        strTokens=cleanDecimal(strTokens);
        let tkns = parseFloat(strTokens);      
        if (Number.isNaN(tkns)) {
            setTokens({
                wei:bigZero,
                ether:strTokens
            });            
        }
        else {
            setTokens({
                wei:parseUnits(tkns.toString()),
                ether:strTokens
            });            
        }
    }

    const handleAddressChange = (event) => {
        let addr=event.target.value;
        setToAddress(addr)
        if (!addr) return;
        addr=addr.trim();
        if (addr==='') {
          return;
        }
        if (addr.startsWith('0x') && utils.isAddress(addr)) {          
          try {
            addr = utils.getAddress(addr);
            setToAddress(addr);
          }
          catch (e) {
            console.log(e);
            setMessage("Error: Invalid checksum on address");
            return;
          }
          setMessage('');
          ponziContract.getTokenBalance(addr, setToTokenBalance);
        }
        else if (addr.length>=5 && addr.endsWith('.eth')) {
          addr = ponziContract.provider.resolveName(addr);
          if (addr) {
            setMessage('');
            ponziContract.getTokenBalance(addr, setToTokenBalance);  
          }
          else {
            setToTokenBalance(initPrice);
          }          
        }
        else {
          setToTokenBalance(initPrice);
        }
        
    }

    const clearTokens = (inputRef) => {
      setTokens(initPrice);
      if (inputRef) {
        inputRef.current.select();
        inputRef.current.focus();        
      }      
    }

    const clearAddress = (inputRef) => {
      setToAddress('');
      if (inputRef) {
        inputRef.current.select();
        inputRef.current.focus();        
      }      
      setMessage('');
    }    

    const tokensInputRef = React.useRef(null);
    const addressInputRef = React.useRef(null);

    const addTransaction = (txn) => {
      let txns = deserializeState(transactions);
      txns.txns.unshift(txn);
      setTransactions(serializeState(txns));        
    }    

      return (                
        <Container maxWidth="sm">     
        
            <Toolbar sx={{alignContent:"right", justifyContent:"right"}}>
                <Tooltip title="Help">
                <IconButton id="transferHelp" color="primary" size="large" onClick={()=>{
                    setHelpOpen(true);                    
                    }}>                    
                    <HelpOutlined id="transferHelpIcon" size="large" />
                </IconButton> 
                </Tooltip>  
            </Toolbar>     

        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>    
          {showAlert(message, setMessage)}                                                           
        </Box>
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>  
        <Typography variant="h5" component="h5">
         Transfer    
         </Typography>
        </Box>
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>                                     
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="tokens">Tokens</InputLabel>
          <OutlinedInput
            id="tokens"
            value={tokens.ether}
            onChange={handleTokensChangeEvent}
            startAdornment={<InputAdornment position="start">{ponziContract.sym}</InputAdornment>}
            endAdornment= {
                <IconButton id="clear_tokens" onClick={() => clearTokens(tokensInputRef)}>
                  <HighlightOffOutlined/>
                </IconButton>
            }            
            label="Amount"
            inputProps={{ inputMode: 'decimal', pattern: '[0-9.]*' }}
            inputRef={tokensInputRef}
            onFocus = {event=>event.target.select()}
          />
          <Typography variant="body3" component="h6" sx={{textAlign:'center'}}>
          Balance: {ponziContract.sym} {PonziContract.formatNumber(myTokens.ether, 9)}
          </Typography>          
        </FormControl>   
        <Typography variant="body2" component="h6" sx={{textAlign:'center'}}>            
        {fiatCcy} {(Object.keys(ethConvRates).length>0) ? 
          (ethConvRates.rates[fiatCcy]*parseFloat(utils.formatEther((tokens.wei.mul(tokenWorth.wei)).div(ponziContract.decimals)))).toFixed(4)
          : 'NA'}                
        </Typography>        
        </Box>
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>  
        <Typography variant="h5" component="h5">
         to
         </Typography>
        <TransferHelpDialog ponziContract={ponziContract} helpOpen={helpOpen} setHelpOpen={setHelpOpen}/>
        <WalletDialog ponziContract={ponziContract} setPonziContract={setPonziContract} 
            walletDialogOpen={walletDialogOpen} setWalletDialogOpen={setWalletDialogOpen}/>       
        </Box>  
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>                             
        <FormControl sx={{ m: 1 }}>
          <InputLabel htmlFor="address">Address</InputLabel>
          <OutlinedInput
            sm={{minWidth:400}}
            fullWidth
            multiline
            id="address"
            value={toAddress}
            onChange={handleAddressChange}              
            endAdornment= {
                <IconButton id="clear_address" onClick={() => {clearAddress(addressInputRef)}}>
                  <HighlightOffOutlined/>
                </IconButton>
            }            
            label="Address"            
            inputRef={addressInputRef}
            onFocus = {event=>event.target.select()}
            inputProps= {{ spellCheck: 'false' }}
            autoCapitalize='none'
          />
          <Typography variant="body3" component="h6" sx={{textAlign:'center'}}>
          Balance: {ponziContract.sym} {PonziContract.formatNumber(toTokenBalance.ether, 9)}
          </Typography>          
        </FormControl>   
        </Box>
        <Box maxWidth="sm" sx={{marginY:2, textAlign:'center', justifyContent:'center'}}>     
             {showActionButton(ponziContract, setWalletDialogOpen, tokens.wei, toAddress, setMyTokens, setToTokenBalance, setMessage, addTransaction.bind(this), 
                ethConvRates, fiatCcy)}
        </Box>        
        </Container>
      )      

}


function showActionButton(ponziContract, setWalletDialogOpen, tokens, toAddress, setTokenBalance, setToTokenBalance, setMessage, addTransaction, ethConvRates, fiatCcy) {
  if (!ponziContract || !ponziContract.provider || !ponziContract.isConnected || !ponziContract.hasSigner) {
    return (
      <Button variant="contained" size="large" sx={{ borderRadius:8 }}
          onClick={()=>setWalletDialogOpen(true)}>Connect Wallet</Button>
    );
  }
  else {
    return (
      <Button variant="contained" size="large" disabled={(!ponziContract || !ponziContract.isConnected || !ponziContract.hasSigner)}
          sx={{ borderRadius:8 }}
          onClick={() => ponziContract.transferTokens(tokens, toAddress, setTokenBalance, setToTokenBalance, setMessage, ethConvRates, fiatCcy, addTransaction.bind(this))}>Go</Button>

    )
  }
}

function TransferHelpDialog({helpOpen, setHelpOpen, ponziContract}) {
  return (
    <Dialog open={helpOpen}>
      <DialogTitle>Transfer {ponziContract.sym} Tokens</DialogTitle>
      <DialogContent>
        Use this screen to transfer tokens to another address - either a full address or an ENS name. Make sure the address you send to is on the same network - &nbsp;
        {(ponziContract && ponziContract.network && ponziContract.network.network) ? ponziContract.network.network.name : 'NA'}, 
        otherwise your tokens will be lost forever.
        
        <DialogActions>
                <Button onClick={()=>setHelpOpen(false)}><HighlightOffOutlined/></Button>
        </DialogActions>        
      </DialogContent>
    </Dialog>
  )
}