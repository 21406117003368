import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

export default function About({fiatCcy, ponziContract, isIdle}) {

return (    
    <Container maxWidth="sm">
        <Box sx={{marginY:0}} maxWidth="sm">     
            <List>
                <ListItem>
                    <ListItemIcon>
                        <img src="ponzi.jpg" height="80" width="50" alt="Charles Ponzi"/>
                    </ListItemIcon>                        
                    <ListItemText>
                        <Typography sx={{fontFamily:'courier,courier new,roboto', fontSize:18, marginLeft:3, marginTop:1, fontWeight:'bold'}} variant="p" component="p">                        
                            You're going to lose your money anyway so why not invest in a ponzi scheme?
                        </Typography>
                    </ListItemText>                    
                </ListItem>
                <ListItem>                    
                    <ListItemIcon>
                    <img src="fivepercent.png" height="80" width="50" alt="5%"/>
                    </ListItemIcon>
                    <ListItemText>
                    <Typography sx={{fontFamily:'helvetica,roboto', fontSize:16, marginLeft:3, marginTop:0}} variant="p" component="p">                        
                        Pay 5% into a common pool when you buy or sell ponzicoin. The value of everybody else's tokens goes up every time you trade. The 
                        pool contribution is already reflected in the buy and sell prices, so what you see is what you get.
                    </Typography>
                    </ListItemText>
                </ListItem>  
                <ListItem>
                    <ListItemIcon>
                        <img src="bag_holder.png" height="80" width="50" alt="5%" style={{marginTop:1}}/>
                    </ListItemIcon>                        
                    <ListItemText>
                        <Typography sx={{fontFamily:'roboto', fontSize:16, marginLeft:3, marginTop:0}} variant="p" component="p">                        
                            There are no bagholders, only winners! The longer you hold, the more your coins are worth. You make money when others buy and sell.
                        </Typography>
                    </ListItemText>                    
                </ListItem>  
                <ListItem>
                    <ListItemIcon>
                        <img src="contract.jpg" height="80" width="50" alt="5%" style={{marginTop:1}}/>
                    </ListItemIcon>                        
                    <ListItemText>
                        <Typography sx={{fontFamily:'roboto', fontSize:16, marginLeft:3, marginTop:0}} variant="p" component="p">                        
                            The most transparent ponzi scheme ever! The contract code is available&nbsp;
                                <Link href="https://ropsten.etherscan.io/address/0x7fBf48c97B616b6B6B9406585BF6eBBE72e48C67#code" target="_blank" rel="noreferrer">here</Link>
                            , and everything you need to get started making money is on this site. Transact on Binance Smart Chain.
                        </Typography>
                    </ListItemText>                    
                </ListItem>   
                <ListItem>
                    <ListItemIcon>
                        <img src="confused.png" height="80" width="50" alt="5%" style={{marginTop:1}}/>
                    </ListItemIcon>                        
                    <ListItemText>
                        <Typography sx={{fontFamily:'roboto', fontSize:16, marginLeft:3, marginTop:0}} variant="p" component="p">                        
                            Still confused? Read our FAQ now, or try it out risk free using the simulator.
                        </Typography>
                    </ListItemText>                    
                </ListItem>    
                <ListItem>
                    <ListItemIcon>
                        <img src="telegram.png" height="60" width="50" alt="5%" style={{marginTop:1}}/>
                    </ListItemIcon> 
                    <ListItemText>
                        Telegram <Link href="https://t.me/pnzponzi" target="_blank" rel="noreferrer">pnzponzi</Link>
                    </ListItemText>
                </ListItem>
            </List>
        </Box>    
    </Container>
    );
}