import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { utils } from "ethers";
import { useState } from "react";
import { PonziContract } from "../utils/ponziContract2";

export default function FAQ({fiatCcy, ponziContract, isIdle}) {
    const [openAccordion, setOpenAccordion] = useState('');
    
    return (            
        <Container maxWidth="sm">

        <HelpElement id="howToStart" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
            How do I get started?   
            </HelpTitle>
            <HelpContent>
                
                <List>
                    <ListItem>
                    <ListItemText>
                    You will need a browser with a crypto wallet.&nbsp;
                    <Link href="https://metamask.io/download/" target="_blank" rel="noreferrer">Metamask</Link> is the most widely used and supported wallet app and works as
                        an app on your phone or an extension on your browser. 
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                    <ListItemText>
                 Use metamask (or any other wallet) to connect to the &nbsp;
                 <Link href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain" target="_blank" rel="noreferrer">binance smart chain</Link>.
                    </ListItemText>
                    </ListItem>
                    <ListItem>
                    <ListItemText>
                 Open this website in the metamask browser on a smartphone or connect using your browser's metamask extension. 
                    </ListItemText>
                    </ListItem>
                    <ListItem>
                    <ListItemText>
                        You'll need some&nbsp;{ponziContract.ccy} in your wallet to buy {ponziContract.sym}. Use an app like&nbsp;
                        <Link href="https://www.coinbase.com/" target="_blank" rel="noreferrer">Coinbase</Link> or&nbsp;
                        <Link href="https://www.binance.com/" target="_blank" rel="noreferrer">Binance</Link>&nbsp;
                        to buy {ponziContract.ccy}
                        </ListItemText>
                    </ListItem>
                 </List>
            </HelpContent>
        </HelpElement> 
        <HelpElement id="whatIsPNZ" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                What is Ponzicoin ({ponziContract.sym}) exactly?
            </HelpTitle>
            <HelpContent>
                Ponzicoin is an ERC20 token. What this means is that there is a contract on the ethereum network that you pay into to receive {ponziContract.sym} 
                tokens. When you pay into the contract, its balance goes up. 
                The value of a token is simply the balance of the contract divided by the number of tokens issued. The contract cannot change and will exist 
                in perpetuity. It is freely available to view&nbsp;
                <Link href={ponziContract.codeUrl} target="_blank" rel="noreferrer">here</Link>              
            </HelpContent>
        </HelpElement>
        <HelpElement id="whyPNZ" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                Why invest in Ponzicoin?
            </HelpTitle>
            <HelpContent>
                All of crypto is a ponzi scheme. Fiat currencies like the US Dollar are also ponzi schemes but they are backed by the real economy where people produce goods and services
                so their value will never drop to zero. Crypto is backed by nothing other than belief and spent electricity that's never coming back. 
                By keeping your money in PNZ you guarantee that when others start panic selling you'll make money by just holding.             
            </HelpContent>
        </HelpElement>
        <HelpElement id="howMakeMoney" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                How do I make money in {ponziContract.sym} when others sell?
            </HelpTitle>
            <HelpContent>
                The value of your {ponziContract.sym} tokens will go up when others sell theirs. This is because the contract retains 5% of the value of each sale 
                to be shared by the remaining token holders.            
            </HelpContent>
        </HelpElement>
        <HelpElement id="pricing" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                How does the value of my {ponziContract.sym} tokens go up?
            </HelpTitle>
            <HelpContent>
                The value of your tokens is the {ponziContract.ccy} balance on the contract divided by the number of tokens issued. 
                Five percent of each purchase or sale goes back into the contract, so the value of everybody's tokens increases when someone buys or sells. 
                Try the simulator to test buying some tokens and then seeing how the price of your tokens goes up as others trade.
            </HelpContent>
        </HelpElement>
        <HelpElement id="valueGoUp" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                How does the value of my {ponziContract.sym} tokens go up?
            </HelpTitle>
            <HelpContent>
                The value of your tokens is the {ponziContract.ccy} balance on the contract divided by the number of tokens issued. Five percent of each purchase or sale goes back 
                into the contract, so the value of everybody's tokens increases when someone buys or sells. Try the simulator to test buying some tokens and then seeing how 
                the price goes up as others trade.
            </HelpContent>
        </HelpElement> 
        <HelpElement id="safety" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                Is this safe?
            </HelpTitle>
            <HelpContent>
                The code for the contract is <Link href={ponziContract.codeUrl} target="_blank" rel="noreferrer">open for anyone to see</Link>. The owner cannot
                stop trading or withdraw the contract balance unanimously.
            </HelpContent>
        </HelpElement>
        <HelpElement id="spread" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                Why is there a difference between the buy and sell price?
            </HelpTitle>
            <HelpContent>
                There is a 5% spread applied to the price. What this means is that when you buy you pay 5% more than what the token is worth and when you 
                sell you get 5% less. The 5% goes towards to contract balance and is shared by all the remaining token holders. There is a floor price of
                {ponziContract.ccy} 0.0001 to buy.
            </HelpContent>
        </HelpElement>
        <HelpElement id="skew" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                What happens to prices when the token supply is low?  
            </HelpTitle>
            <HelpContent>
                When the total number of tokens issued falls below 10,000 a skew is applied to the spread. What this means is that the contribution you pay
                to buy is less than 5% and to sell its more than 5%. This is to encourage people to buy to bring up the token supply which benefits all 
                token holders. If the supply falls to just one token, selling is prohibited to prevent the price of the token rising to infinity.
                Once the supply reaches 10,000 the usual 5% spread applies.
            </HelpContent>
        </HelpElement>  
        <HelpElement id="skew" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                Is buying and selling the same as minting and burning?  
            </HelpTitle>
            <HelpContent>
                Yes
            </HelpContent>
        </HelpElement>     
        <HelpElement id="contact" openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
            <HelpTitle>
                How do I get in touch?
            </HelpTitle>
            <HelpContent>
                <Box sx={{marginY:0, flexDirection:"row"}} maxWidth="sm" display="flex">
                <img src="telegram.png" length={36} height={36} alt="Telegram logo"/>
                <Typography sx={{marginLeft:2, marginTop:1}}>
                Telegram <Link href="https://t.me/pnzponzi" target="_blank" rel="noreferrer">pnzponzi</Link>
                </Typography>
                </Box>
            </HelpContent>
        </HelpElement>          
        </Container>
    )
}

const HelpElement = ({id, openAccordion, setOpenAccordion, children}) => {
    return (
    <Accordion sx={{marginY:2}} expanded={openAccordion===id} onChange={(event, expanded)=>{
        setOpenAccordion((openAccordion===id) ? '' : id);            
        }}>   
        {children}
    </Accordion>
    ); 
}

const HelpTitle = ({children}) => 
    <AccordionSummary expandIcon={<ExpandMore/>}>
        <Typography variant="h6" component="h6" marginTop={0}>
        {children}
        </Typography>
    </AccordionSummary>
    



const HelpContent = ({children}) => 
    <AccordionDetails>
        {children}
    </AccordionDetails>
