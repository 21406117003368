import React from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import { Article, TrendingUp, Analytics, Speed, QuestionMark, SwapHoriz } from "@mui/icons-material";

export default function MainMenu({open, onClose, onOpen, openPage, setOpenPage}) {
  return (    
      <Drawer anchor="left" open={open} onClose={onClose}  variant="temporary" sx={{marginY:10}}>
          <Box sx={{width: 200}} role="presentation" onClick={onClose} onKeyDown={onClose}>
            <List>
                <ListItemButton onClick={()=>setOpenPage('trade')}>
                    <ListItem>
                        <ListItemIcon><TrendingUp/></ListItemIcon>
                        <ListItemText>Trade</ListItemText>
                    </ListItem>
                </ListItemButton>
                <ListItemButton onClick={()=>setOpenPage('tradeStats')}>
                    <ListItem>
                        <ListItemIcon><Analytics/></ListItemIcon>
                        <ListItemText>Stats</ListItemText>
                    </ListItem>
                </ListItemButton>
                <ListItemButton onClick={()=>setOpenPage('transfer')}>
                    <ListItem>
                        <ListItemIcon><SwapHoriz/></ListItemIcon>
                        <ListItemText>Transfer</ListItemText>
                    </ListItem>
                </ListItemButton>
                <ListItemButton onClick={()=>setOpenPage('tradeSimulator')}>
                    <ListItem>
                        <ListItemIcon><Speed/></ListItemIcon>
                        <ListItemText>Simulator</ListItemText>
                    </ListItem>
                </ListItemButton>
                
                <ListItemButton onClick={()=>setOpenPage('about')}>
                    <ListItem>
                        <ListItemIcon><Article/></ListItemIcon>
                        <ListItemText>About</ListItemText>
                    </ListItem>
                </ListItemButton>   
                <ListItemButton onClick={()=>setOpenPage('faq')}>
                    <ListItem>
                        <ListItemIcon><QuestionMark/></ListItemIcon>
                        <ListItemText>FAQ</ListItemText>
                    </ListItem>
                </ListItemButton>                                
            </List>
          </Box>
     </Drawer>    
  );
}