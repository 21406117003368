import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { useState } from "react";
import MainMenu from "./components/MainMenu";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Button, CssBaseline, Dialog, DialogContent, DialogTitle, List, ListItem } from '@mui/material';
import Trade from './pages/Trade';
import TopBar from './components/TopBar';
import { PonziContract } from './utils/ponziContract2';

//import WalletConnectProvider from "@walletconnect/web3-provider";
import TradeStats from './pages/TradeStats';
import { useIdleTimer } from 'react-idle-timer'
import SnoozeOutlinedIcon from '@mui/icons-material/SnoozeOutlined';
import TradeSimulator from './pages/TradeSimulator';
import Transfer from "./pages/Transfer";
import { serializeState } from './utils/utils';
import About from './pages/About';
import FAQ from './pages/FAQ';

const { ethers } = require("ethers");


//const rinkebyUrl="https://rinkeby.infura.io/v3/40155b0e3b164471a2122030d5995f2a";
//const ganacheUrl="http://localhost:9545";
//const nodeUrl=rinkebyUrl;
const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const themeDict = {
  'light': lightTheme,
  'dark': darkTheme
}


//const GlobalContext = React.createContext(globalData)

const useSemiPersistentState = (key, initialState) => {
  const [value, setValue] = React.useState(localStorage.getItem(key) || initialState);

  React.useEffect(() => {
    localStorage.setItem(key, value);
  }, [value, key]);

  return [value, setValue];
};

const initTxnState = {txns:[]};
const initPonziContract = new PonziContract(null);
function App() {
  const [menuOpen, setMenuOpenState] = useState(false);
  const [theme, setTheme] = useSemiPersistentState('theme1', 'dark');  
  const [fiatCcy, setFiatCcy] = useSemiPersistentState('fiatCcy', 'USD'); //useState('USD');  
  const [transactions, setTransactions] = useSemiPersistentState('txns2', serializeState(initTxnState));
  
  
  
  const [openPage, setOpenPage] = useSemiPersistentState('openPage1', 'trade'); 
  
  const [ponziContract, setPonziContract] = useState(initPonziContract);  

  ponziContract.simulator=(openPage==='tradeSimulator');
  const [isIdle, setIsIdle] = useState(false);

  const onIdle = () => {
    setIsIdle(true);
  }

  const onActive = () => {
    //setIsIdle(false);
  }

  const idleTimer = useIdleTimer({ onIdle, onActive, timeout:900000 });
  

  const detectProvider = () => {
      //if (ponziContract.provider) return;
      //if (ponziContract.isConnected) return;
      
      if (ponziContract.hasSigner && !(openPage!=='tradeSimulator' && ponziContract.simulator)) return;
      if (window.ethereum) {
          connectProvider(0);
      }
      else {          
          window.addEventListener('ethereum#initialized', ()=>connectProvider(0), {
              once: true,
            }); 
          setTimeout(()=>connectProvider(0), 3000);           
      }
  }
  

  const connectProvider = async (numAtttempts) => {    
    let prv;
    if (ponziContract.hasSigner && !(openPage!=='tradeSimulator' && ponziContract.simulator)) return;
    ethers.logger.info("Attempting connection: "+numAtttempts);    
    if (window.ethereum) {
      prv=new ethers.providers.Web3Provider(window.ethereum);
      await prv.send("eth_requestAccounts", []);      
      prv.on("network", (newNetwork, oldNetwork) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        if (oldNetwork) {
            window.location.reload();
        }
      });   
      //setProvider(prv);    
    }
    else if (numAtttempts<2) {
      setTimeout(()=>connectProvider(numAtttempts+1), 3000);
      return;
    }
    else {
      return;
    }
    //setProvider(prv);
    //let pzc = new PonziContract(prv);
    //setPonziContract(pzc);
    setPonziContract(new PonziContract(prv));
    //PonziContract.instantiate(prv, setConnected);
  }
  
  React.useEffect(()=>{
    if (openPage!=='tradeSimulator') {
      detectProvider();
    }
  }, [openPage]);  

  const handleFiatCcyChange = (event) => {
    setFiatCcy(event.target.value);
  }







  const toggleDrawer = () => setMenuOpenState(!menuOpen);
  const closeDrawer = () => setMenuOpenState(false);
  const openDrawer = () => setMenuOpenState(true);


  const toggleTheme = () => {
    if (theme==='dark') setTheme('light')
    else setTheme('dark');
  }

  return (
    
    <ThemeProvider theme={themeDict[theme]}>
      <CssBaseline/>
      
      <TopBar fiatCcy={fiatCcy} ponziContract={ponziContract} handleFiatCcyChange={handleFiatCcyChange} toggleDrawer={toggleDrawer.bind(this)} toggleTheme={toggleTheme.bind(this)} 
        theme={theme}/>
      <MainMenu open={menuOpen} onClose={closeDrawer} onOpen={openDrawer} openPage={openPage} setOpenPage={setOpenPage} />
      {showIdleDialog(isIdle, setIsIdle, ponziContract)}
      <Box sx={{marginTop:10}}>
      <OpenPage openPage={openPage} fiatCcy={fiatCcy} ponziContract={ponziContract} setPonziContract={setPonziContract} isIdle={isIdle} transactions={transactions} setTransactions={setTransactions}/>
      {/*showOpenPage(openPage, fiatCcy, ponziContract, setPonziContract, isIdle)*/}
      </Box>
    </ThemeProvider>

  );
}

function showIdleDialog(isIdle, setIsIdle, ponziContract) {
  if (isIdle) {
    return (      
      <Dialog onClose={()=>setIsIdle(false)} open={isIdle}>
        <DialogTitle>
          <Box sx={{marginY:0, flexDirection:"row"}} maxWidth="sm" display="flex">                  
          <SnoozeOutlinedIcon size="large" sx={{marginY:0, marginTop:0}}/>
          &nbsp;
           Hello are you there?           
           </Box>
        </DialogTitle>
        <DialogContent>                
          <List>
            <ListItem>
            You've been quiet for a while. If you'd like to continue having fun with {ponziContract.sym} press the button below
            </ListItem>
            <ListItem sx={{justifyContent:"center", alignContent:"center"}}>
            <Button variant="outlined" onClick={()=>{setIsIdle(false)}}>I'm here</Button>
            </ListItem>
          </List>                
        </DialogContent>
      </Dialog>
    )
  }
}
function OpenPage({openPage,fiatCcy, ponziContract, setPonziContract, isIdle, transactions, setTransactions}) {
  switch(openPage) {
    case 'trade':
      return (<Trade fiatCcy={fiatCcy} ponziContract={ponziContract} setPonziContract={setPonziContract} isIdle={isIdle} 
        transactions={transactions} setTransactions={setTransactions}/>);
    case 'tradeStats':
      return (<TradeStats fiatCcy={fiatCcy} ponziContract={ponziContract} setPonziContract={setPonziContract} isIdle={isIdle} activeUser="user" 
        transactions={transactions} setTransactions={setTransactions} />);
    case 'transfer':
        return (<Transfer ponziContract={ponziContract} fiatCcy={fiatCcy} isIdle={isIdle} transactions={transactions} setTransactions={setTransactions}/>);
    case 'tradeSimulator':
      return (<TradeSimulator fiatCcy={fiatCcy} isIdle={isIdle}/>)    
    case 'about':
      return (<About fiatCcy={fiatCcy} ponziContract={ponziContract} isIdle={isIdle}/>)
    case 'faq':
      return (<FAQ fiatCcy={fiatCcy} ponziContract={ponziContract} isIdle={isIdle}/>)
  }
}
export default App;
